import React, { useState, useEffect, useContext } from "react";
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Alert from 'react-bootstrap/Alert';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import OrgService from "../../API/OrgService";
import Dropdown from 'react-bootstrap/Dropdown';
import MyToggle from "../UI/MyDropdown/MyToggle";
import MyMenu from "../UI/MyDropdown/MyMenu";
import { AuthContext } from "../../context";
import Auth from "../../API/Auth";
import Preloader from "../UI/Preloader/Preloader";
import ApiGatewayService from "../../API/ApiGatewayService";
import MultiSelect from "../ApiGateway/MultiSelect/MultiSelect";
import SandboxService from "../../API/SandboxService";
import { getReverseDate } from "./utils";
import MultiSelectAsset from "./MultiSelect/MultiSelect";
function PatchLicenseForm({ setAddLicense, id, data }) {

    const [itemOrg, setItemOrg] = useState({id: data?.organisation, name: data.organisation_name});

    const [issuedDate, setIssuedDate] = useState(getReverseDate(data?.issued_at));
    const [endDate, setEndDate] = useState(getReverseDate(data?.license_expires_at));
    const [allOrganisations, setAllOrganisations] = useState(null);
    const [allAudience, setAllAudience] = useState([])
    const [allScope, setAllScope] = useState([])
    const [errors, setErrors] = useState([]);
    const [active, setActive] = useState(data?.is_active);
    const [isPreloader, setIsPreloader] = useState(false);
    const [tokenDescription, setTokenDescription] = useState(data?.description || '');
   // const [type, setType] = useState('trial');
    const [name, setName] = useState(data?.name || '');
    const [audience, setAudience] = useState({name: data?.license_audience});
    const [reqPerDay, setReqPerday] = useState(data?.daily_requests_limit || 0);
    const [selectedScopes, setSelectedScopes] = useState(data?.license_scope.map((item)=> item.id|| []));

    const [orgDevices, setOrgDevices] = useState([]);
    const [devices, setDevices] = useState(data?.devices?.map((item)=> item.id)|| []);
    //контекст
    const { setStatus } = useContext(AuthContext);
    const changeStatus = () => setStatus(true);

    useEffect(() => {

        (async () => {

            await OrgService.getDropList().then((res) => setAllOrganisations(res)).catch(() => changeStatus());
            await ApiGatewayService.getAudienceList().then((res) => { setAllAudience(res) }).catch((err) => changeStatus())
            await ApiGatewayService.getScopeList().then((res) => { setAllScope(res) }).catch((err) => changeStatus())
        })();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(()=> {
        (async ()=> {  
         if (itemOrg) {
            setDevices([])
        await SandboxService.getAssetsList(itemOrg.id).then((res)=> setOrgDevices(res)).catch((err)=> console.log(err))
            }})()
         
        },[itemOrg])
        useEffect(()=> {
            if (data?.license_audience && allAudience) {
                setAudience(allAudience?.filter((it)=> it.name === data?.license_audience)[0])
            }
              },[allAudience])
    useEffect(() => {
if (id){
        (async () => {

            await SandboxService.getLicenseDetail(id).then((res) => {
                setDevices(res.devices?.map((item)=> item.id)|| [])
            }).catch(() => changeStatus());
            
        })();
}
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);
    const refreshToken = async (type) => {
        await Auth.refreshToken(localStorage.getItem('freshToken'))
            .then((res) => {
                localStorage.setItem('token', res.data.access); setErrors([]);
                if (type === 'save') saveLicense();

            })
            .catch(() => changeStatus())
    }

    const saveLicense = async event => {
        setIsPreloader(true);

        if (event) event.preventDefault();
        const token = {};
        token.is_active = active
        token.organisation = itemOrg.id;
        token.name = name;
        //token.issued_at = issuedDate + 'T' + '00:00Z';
        token.license_expires_at = endDate + 'T23:59Z';
        token.devices = devices;
        token.license_audience = audience.id;
        token.license_scope = selectedScopes;
        token.description = tokenDescription? tokenDescription: null;
        token.daily_requests_limit = reqPerDay;

        await SandboxService.patchLicense(data?.id, token)
            .then(() => { setAddLicense(false); setIsPreloader(false); })
            .catch((err) => {
                if (err.message[0].includes('token')) refreshToken('save');
                else {
                    setErrors(err.message); setIsPreloader(false);
                }
            })
    }

    return (
        <Form id="form" onSubmit={saveLicense} className="was-validated" style={{ position: "relative" }}>
            {errors.length > 0 &&
                errors.map(error =>
                    <Alert key={"danger" + error} variant="danger">
                        {error}
                    </Alert>
                )
            }
            {isPreloader && <Preloader />}

         <Row className="mb-3">
         <Col className="col-2">
                    <div
                        className="form-check form-switch"
                        style={{
                            minHeight: 0, display: "flex", flexDirection: "column"
                        }}
                    >
                           <Form.Label size="sm" style={{marginLeft: '-40px'}}>Activate</Form.Label>
                        <input
                            className="form-check-input"
                            type="checkbox"
                            id="statusChecks"
                            checked={active}
                            style={{ height: "25px", width: "50px"}}
                            onChange={(evt) => setActive(evt.target.checked)}
                        />
                    </div>
                </Col>
         </Row>
            <Row>
                <Col className=" col-4">
                    <Form.Group className="mb-2">
                        <Form.Label size="sm">Название</Form.Label>
                        <Form.Control size="sm" type='text' value={name} onChange={(e) => setName(e.target.value)} required={true}>

                        </Form.Control>
                    </Form.Group>
                </Col>
                <Col sm={4}>
                    <Form.Group className="mb-2">
                        <Form.Label size="sm">Организация</Form.Label>
                        <Form.Control size="sm"  value={itemOrg?.name} disabled>
                        </Form.Control>
                        {/* <Dropdown style={{ border: "1px solid green", height: 31, borderRadius: 6, paddingLeft: '.5rem' }}>
                            <Dropdown.Toggle as={MyToggle} id="dropdown-orgs" >
                                {itemOrg?.name || "выбрать"}
                            </Dropdown.Toggle>
                            <Dropdown.Menu
                                as={MyMenu}
                                type={'Org'}
                                filterQa={true}
                            >
                                {allOrganisations?.map(org =>
                                    <Dropdown.Item eventKey={"e" + org.id} key={"e" + org.id} onClick={() => { setItemOrg(org); }}>{org.name}</Dropdown.Item>
                                )}
                            </Dropdown.Menu>
                        </Dropdown> */}

                    </Form.Group>
                </Col>
                <Col className=" col-4">
                    <Form.Group className="mb-2">
                        <Form.Label size="sm">Лимит запросов в сутки</Form.Label>
                        <Form.Control size="sm" type='number' value={reqPerDay} onChange={(e) => setReqPerday(e.target.value)} required={true}>

                        </Form.Control>
                    </Form.Group>
                </Col>
            </Row>
            <Row>
                <Col sm={4}>
                    <Form.Group className="mb-3" required={true} >
                        <Form.Label >Дата активации </Form.Label>
                        <Form.Control
                        disabled
                            value={issuedDate || ""}
                            onChange={(e) => setIssuedDate(e.target.value)}
                            size="sm" type="date"
                            required={true} />

                    </Form.Group>
                </Col>
                <Col sm={4}>
                    <Form.Group className="mb-3" required={true} >
                        <Form.Label >Дата окончания </Form.Label>
                        <Form.Control
                            value={endDate || ""}
                            onChange={(e) => setEndDate(e.target.value)}
                            size="sm" type="date"
                            required={true} />

                    </Form.Group>
                </Col>
                </Row>
                <Row>
                <Col sm={4}>
                    <Form.Group className="mb-2">
                        <Form.Label size="sm">Сервис</Form.Label>

                        <Dropdown style={!audience ? { border: "1px solid red", height: 31, borderRadius: 6, paddingLeft: '.5rem' } : { border: "1px solid green", height: 31, borderRadius: 6, paddingLeft: '.5rem' }}>
                            <Dropdown.Toggle as={MyToggle} id="dropdown-audience" >
                                {audience?.name || "выбрать"}
                            </Dropdown.Toggle>
                            <Dropdown.Menu
                                as={MyMenu}
                                type={'audience'}
                                // filterQa={true}
                            >
                                {allAudience?.map(item =>
                                    <Dropdown.Item eventKey={"au" + item.id} key={"au" + item.name} onClick={() => { setAudience(item);setSelectedScopes([]) }}>{item.name}</Dropdown.Item>
                                )}
                            </Dropdown.Menu>
                        </Dropdown>

                    </Form.Group>
                </Col>
                <Col sm={4}>
                    <Form.Group className="mb-2">
                        <Form.Label size="sm">Ресурсы и доступы</Form.Label>

                       { audience && <MultiSelect data={audience?.scopes || data?.license_scope} selectedScopes={selectedScopes} setSelectedScopes={setSelectedScopes} disabled={audience? false: true}/>}

                    </Form.Group>
                </Col>
               
            </Row>
            <Row>
            {itemOrg && orgDevices?.length>0 &&  <Col sm={8}>
                    <Form.Group className="mb-2">
                        <Form.Label size="sm">Устройства организации</Form.Label>

                       {  <MultiSelectAsset data={orgDevices || []} selectedScopes={devices} setSelectedScopes={setDevices} disabled={itemOrg? false: true}/>}

                    </Form.Group>
                </Col>}
            </Row>
            <Row>

                <Col sm={4}>
                    <Form.Group className="mb-4">
                        <Form.Label size="sm">Описание</Form.Label>
                        <Form.Control
                            as="textarea"
                            rows={2}
                            type="text"
                            placeholder="Описание "
                            value={tokenDescription || ""}
                            size="sm"
                            onChange={(e) => setTokenDescription(e.target.value)}
                        />
                    </Form.Group>
                </Col>

                {/* {issuedDate && tokenDuration && <Col sm={2}>
                    <Form.Group className="mb-3" required={true} >
                        <Form.Label >Дата окончания</Form.Label>
                        <Form.Control
                  
                            value={getExpDate()}
                            // onChange={(e) => setExpiresDate(e.target.value)}
                            disabled={true}
                            size="sm" type="date"
                        />

                    </Form.Group>
                </Col>} */}
                {/* {issuedDate && tokenDuration &&<Col sm={2}>
                <Form.Group className="mb-3" required={true} >
                    <Form.Label style={{ color: "white" }}>Time</Form.Label>
                    <Form.Control
                        value={issuedTime}
                                disabled={true}
                        size="sm" type="time"
                        required={true} />

                </Form.Group>
            </Col>} */}
            </Row>
            <Row >
                <Col className="mb-3 col-2" >
                    <Form.Group className="mb-4"  >
                        <Button style={{ width: '100%' }} variant="outline-danger" size="sm" onClick={() => {
                            setAddLicense(false);

                        }}>
                            Отменить
                        </Button>
                    </Form.Group>
                </Col>
                <Col className="mb-3 col-2" >
                    <Form.Group className="mb-4">
                        <Button style={{ width: '100%' }} variant="outline-primary" size="sm" type="submit">
                            Сохранить
                        </Button><br />
                    </Form.Group>
                </Col>
            </Row>


            <hr />
        </Form >
    );
}
export default PatchLicenseForm;
