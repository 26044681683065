import React, { useState, useContext, useEffect } from "react";
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Alert from 'react-bootstrap/Alert';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ImgAdd from '../../icons/add-plus.svg';
import Auth from "../../API/Auth";
import { AuthContext } from "../../context";
import Preloader from "../UI/Preloader/Preloader";
import UpdatesCloudService from "../../API/UpdatesCloudService";
import SandboxService from "../../API/SandboxService";
import ImgRemove from '../../icons/cross-delete.svg';
import { Dropdown } from "react-bootstrap";
import MyToggle from "../UI/MyDropdown/MyToggle";
import MyMenu from "../UI/MyDropdown/MyMenu";
function AddAssetForm({ setAddInstance }) {
    const getRandomId=()=> {
        let randomId;
       
        const r = (min, max) => Math.floor(Math.random() * (max - min + 1)) + min;
       
       
const rs = (len) => new Array(len).fill(0).map(v => String.fromCharCode(r(97, 122))).join("");
randomId =rs(100)
return randomId
    }

    const [serialNumber, setSerialNumber] = useState('');
    const [active, setActive] = useState(false)
    const [checkData, setCheckData] = useState(null)
    const [comment, setComment] = useState('')
    const [ipAsset, setIpAsset] = useState([{value:'', name: '', id: getRandomId()}])
    const [isPreloader, setIsPreloader] = useState(false);
    const [checkErrors, setCheckErrors] = useState('');
    const [errors, setErrors] = useState([]);
    const [licenseList, setLicenseList] = useState([])
    const [license, setLicense] = useState(null);
    const [manufacturer, setManufacturer] = useState(null)
    //контекст
    const { setStatus } = useContext(AuthContext);
    const changeStatus = () => setStatus(true);

    const refreshToken = async (type) => {
        await Auth.refreshToken(localStorage.getItem('freshToken'))
            .then((res) => {
                localStorage.setItem('token', res.data.access); setErrors([]);
                saveAsset();
            })
            .catch(() => changeStatus())
    }

    const saveAsset = async event => {
        if (event) event.preventDefault();
        const asset = {};
        setIsPreloader(true)
        asset.firewall = checkData?.id;
        asset.is_active = active;
        asset.organisation = checkData?.organisation_info[0].id;
        if (license) asset.license = license?.license_token
        if (manufacturer) asset.type = manufacturer?.key
        let arr = []
        ipAsset.forEach((item) => { 
             arr.push(item.value) })
        asset.device_ip = arr
        asset.comment = comment;
          await SandboxService.addInstance(asset).then(() => { setAddInstance(false); setIsPreloader(false) })
            .catch((err) => {
                if (err.message[0].includes('token')) refreshToken();
                else {
                    setErrors(err.message); setIsPreloader(false);
                }
            })
   
    }
useEffect(()=> {
    ( async()=> { 
if (checkData) {
        
    
      const filter = {organisation: checkData?.organisation_info[0].id }
await SandboxService.getLicensesList(filter).then ((res)=> setLicenseList(res.results)).catch((err)=> console.log(err))}})()

    
},[checkData])
    const checkDeviceInDb = async (serial) => {
        setIsPreloader(true);
        await UpdatesCloudService.checkAsets(serial)

            .then((res) => {
                if (res.count > 0) setCheckData(res.results[0])
                else { setCheckErrors('Устройство в базе не найдено') }
            }).catch((err) => changeStatus()).finally(() => setIsPreloader(false))
    }
    const toggleDone = (id, value) => {
        let updatedList = []
        updatedList = ipAsset.map(item => {
            if (item.id === id) {
                return { ...item, value: value, name: value }; //change input value
            }
            return item // return unmodified
        });

        setIpAsset(updatedList); // updated state
    }

    return (
        <Form id="form" onSubmit={saveAsset} className="was-validated" >
            {errors?.length > 0 &&
                errors?.map(error =>
                    <Alert key="danger" variant="danger">
                        {error}
                    </Alert>
                )
            }
            {isPreloader && <Preloader />}


            <Row>
            <Col className="col-2 mb-3">
                    <div
                        className="form-check form-switch"
                        style={{
                            minHeight: 0, display: "flex", flexDirection: "column"
                        }}
                    >
                           <Form.Label size="sm" style={{marginLeft: '-40px'}}>Activate</Form.Label>
                        <input
                            className="form-check-input"
                            type="checkbox"
                            id="statusChecks"
                            checked={active}
                            style={{ height: "25px", width: "50px"}}
                            onChange={(evt) => setActive(evt.target.checked)}
                        />
                    </div>
                </Col>

            </Row>
            {checkErrors && <Row>
                <Col sm={4}>


                    <Alert key="danger" variant="danger">
                        {checkErrors}
                    </Alert>
                </Col>
            </Row>}

            <Row>
                <Col sm={4}>
                    <Form.Group>
                        <Form.Label size="sm">Серийный номер устройства</Form.Label>
                        <Form.Control size="sm" type="text" value={serialNumber} required={true} placeholder={'Введите серийный номер'} name='sn'
                            onChange={(e) => { setCheckErrors(''); setCheckData(null); setSerialNumber(e.target.value); setLicense(null) }}
                        />
                        <Form.Text className="text-muted">
                        </Form.Text>
                    </Form.Group>
                </Col>
                <Col className=" col-2" >
                    <Form.Group className="mb-4">
                        <Form.Label size="sm" style={{ color: "white" }}>Check device</Form.Label>
                        <Button style={{ width: '100%' }} variant="success" size="sm" onClick={() => checkDeviceInDb(serialNumber)} disabled={!serialNumber}>
                            Найти в базе
                        </Button>
                    </Form.Group>
                </Col>

            </Row>
            {checkData && <Row className="mb-3">
                <Col sm={4}>
                    <Form.Group>
                        <Form.Label size="sm">Организация</Form.Label>
                        <Form.Control size="sm" type="text" value={checkData?.organisation} disabled
                        />
                        <Form.Text className="text-muted">
                        </Form.Text>
                    </Form.Group>
                </Col>
                <Col sm={4}>
                    <Form.Group>
                        <Form.Label size="sm">Платформа</Form.Label>
                        <Form.Control size="sm" type="text" value={checkData?.platform_name} disabled
                        />
                        <Form.Text className="text-muted">
                        </Form.Text>
                    </Form.Group>
                </Col>
                
            </Row>}
            <Row>
           { checkData && <Col sm={4}>
                    <Form.Group className="mb-2">
                        <Form.Label size="sm">Лицензия</Form.Label>

                        <Dropdown style={{ border: "1px solid green", height: 31, borderRadius: 6, paddingLeft: '.5rem' }}>
                            <Dropdown.Toggle as={MyToggle} id="license-orgs" >
                                {license?.name || "выбрать"}
                            </Dropdown.Toggle>
                            <Dropdown.Menu
                                as={MyMenu}
                                type={'Lic'}
                                filterQa={true}
                            >
                                {licenseList?.map(lic =>
                                    <Dropdown.Item eventKey={"e" + lic.id} key={"e" + lic.id} onClick={() => { setLicense(lic);  }}>{lic.name}</Dropdown.Item>
                                )}
                            </Dropdown.Menu>
                        </Dropdown>

                    </Form.Group>
                </Col>}
            </Row>
            <Row>
           { checkData && <Col sm={4}>
                    <Form.Group className="mb-2">
                        <Form.Label size="sm">Manufacturer</Form.Label>

                        <Dropdown style={{ border: "1px solid green", height: 31, borderRadius: 6, paddingLeft: '.5rem' }}>
                            <Dropdown.Toggle as={MyToggle} id="man-orgs" >
                                {manufacturer?.name || "выбрать"}
                            </Dropdown.Toggle>
                            <Dropdown.Menu
                                as={MyMenu}
                                type={'Man'}
                                filterQa={true}
                            >
                               
                                    <Dropdown.Item eventKey={"e" + 'pa'} key={"e" + 'pa'} onClick={() => { setManufacturer({key: 'palo_alto', name: 'Palo Alto Networks'});  }}>{'Palo Alto Networks'}</Dropdown.Item>
                                    <Dropdown.Item eventKey={"e" + 'fort'} key={"e" + 'fort'} onClick={() => { setManufacturer({key: 'fortinet', name: 'Fortinet'});  }}>{'Fortinet'}</Dropdown.Item>
                                    <Dropdown.Item eventKey={"e" + 'check'} key={"e" + 'check'} onClick={() => { setManufacturer({key: 'checkpoint', name: 'Checkpoint'})  }}>{'CheckPoint'}</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>

                    </Form.Group>
                </Col>}
            </Row>
            <Row className="mb-3">
                <Col sm={4}>
                    <Form.Group>
                        <Form.Label size="sm">Комментарий (опционально)</Form.Label>
                        <Form.Control size="sm" type="text" value={comment} onChange={((e) => setComment(e.target.value))}
                        />
                        <Form.Text className="text-muted">
                        </Form.Text>
                    </Form.Group>
                </Col>
            </Row>
            
            {ipAsset?.map((item, index) => <Row key={item.id}>

                <Col className="mb-3 col-4">
                    <Form.Group>
                        <Form.Label size="sm">IP</Form.Label>
                        <Form.Control size="sm" type="text" iid={'nameFile' + getRandomId()} required={true} placeholder={'Введите IP'}
                            onChange={(e) => { toggleDone(item.id, e.target.value)}}
                        />
                        <Form.Text className="text-muted">
                        </Form.Text>
                    </Form.Group>
                </Col>
                <Col> <>


                    <img width="20" className="interactive_hover" alt={'add'} src={ImgAdd} style={{ marginTop: '33px', marginRight: '10px' }}
                        onClick={() => { setIpAsset([...ipAsset, { id: getRandomId(), value: '', name: '' }]) }}
                    />
                {ipAsset?.length>1 && <img width="20" className="interactive_hover" alt={'delete'} src={ImgRemove} style={{ marginTop: '33px' }}
                    onClick={() => {
                        setIpAsset(ipAsset.filter((it) => it.id !== item.id))
                    }} />}</></Col>
            </Row>)}

            <Row >
                <Col className="mb-3 col-2" >
                    <Form.Group className="mb-4"  >
                        <Button style={{ width: '100%' }} variant="outline-danger" size="sm" onClick={() => {
                            setAddInstance(false);

                        }}>
                            Отменить
                        </Button>
                    </Form.Group>
                </Col>
                <Col className="mb-3 col-2" >
                    <Form.Group className="mb-4">
                        <Button style={{ width: '100%' }} variant="outline-primary" size="sm" type="submit"

                            disabled={!checkData}
                        >
                            Добавить
                        </Button><br />
                    </Form.Group>
                </Col>
            </Row>
            <hr />
        </Form >
    );
}
export default AddAssetForm;
