import React, { useState, useEffect, useRef, useContext } from "react";
import Col from "react-bootstrap/esm/Col";
import Row from "react-bootstrap/esm/Row";
import { AuthContext } from "../../context";
import SandboxService from "../../API/SandboxService";
import Button from "react-bootstrap/esm/Button";
import Preloader from "../UI/Preloader/Preloader";
import { Alert,  Table } from "react-bootstrap";
import downloadPdf from "../../icons/pdf.svg";
import {
    dateEndStatus,
    getDevicesMetrics,
    getManufactureIcon,
    getSeverityBadge,
    getVerdictBadgeForti,
    getVerdictBadgeKasper,
    getVerdictKATAPBadge,
    getVerdictPaBadge,
    getVerdictTVBadge,
} from "./utils";
import forti from "../../icons/fortinet.svg";
import palo from "../../icons/PANW.svg";
import kasper from "../../icons/kasper.svg";
import kasper_sandbox from "../../icons/product_logo.png";
import kasper_atap from '../../icons/rs-b-kata.png'
import CopyIcon from '../../icons/copy.svg';
function LicenseDetail({ setviewFileDetail, license_subject }) {
    const [licenseData, setLicenseData] = useState(null);
    const [isPreloader, setIsPreloader] = useState(false);
    const [copyText, setCopyText] = useState(false)
    const [errors, setErrors] = useState(null);

    //контекст
    const { setStatus } = useContext(AuthContext);
    const changeStatus = () => setStatus(true);

    const basicStylesTitles = {
        fontSize: "smaller",
        width: "30%",
        textAlign: "right",
        textTransform: 'uppercase'
    };
    const fetchData = async () => {
        setIsPreloader(true);
        await SandboxService.getLicenseDetail(license_subject)
            .then((res) => {
                setLicenseData(res);
            })
            .catch(() => changeStatus())
            .finally(() => setIsPreloader(false));
    };

    useEffect(() => {
      
        if (license_subject) {
            fetchData();
        }
    }, [license_subject]);


    const changeDate = (date) => {
        if (date) {
            const formatDate = date?.split("T");
            const reverseDate = (
                <>
                    <span>{formatDate[0].split("-").reverse().join(".")}</span>{" "}
                    <span>{formatDate[1].split(".")[0]}</span>
                </>
            );

            return reverseDate;
        } else return null;
    };
    

    const copyBufferText = ( text, field ) => {
        navigator.clipboard
            .writeText(text)
            .then((res) => {
                setCopyText({ text: "Copy", field: field });
            })
            .catch((err) => {
                setCopyText({ text: "Error", field: field });
            });
    };
 
    
    
    return (
        <>
            <div
                style={{
                    width: "900px",
                    margin: "0 auto",
                    maxHeight: "90vh",
                    height: 900,
                }}
            >
                <div
                    style={{
                        position: "relative",
                        display: "flex",
                        justifyContent: "space-between",
                        marginBottom: "5px",
                    }}
                >
                    <h5>{licenseData?.file_name || ""}</h5>
                    <Button
                        variant="outline-primary"
                        onClick={() => setviewFileDetail(false)}
                    >
                        Закрыть
                    </Button>
                </div>
                <Row style={{ margin: "25px 0 0", position: "relative" }}>
                    {isPreloader && <Preloader />}
                    {
                        <Table
                            bordered
                            striped
                            hover
                            id="DetailFile"
                            style={{ marginBottom: 20, fontSize: 14 }}
                        >
                            <tbody>
                                <tr>
                                    <th style={basicStylesTitles}>ИМЯ</th>
                                    <td style={{ fontSize: 12 }}>
                                        {licenseData?.name}
                                    </td>
                                </tr>
                                <tr>
                                    <th style={basicStylesTitles}>
                                      ОПИСАНИЕ
                                    </th>
                                    <td>
                                    {licenseData?.description}
                                    </td>
                                </tr>
                                <tr>
                                    <th style={basicStylesTitles}>
                                       Организация
                                    </th>
                                    <td>
                                        {licenseData?.organisation_name}
                                    </td>
                                </tr>
                                <tr>
                                    <th style={basicStylesTitles}>
                                        дата начала (UTC)
                                    </th>
                                    <td>
                                        {licenseData?.issued_at}
                                    </td>
                                </tr>
                                <tr>
                                    <th style={basicStylesTitles}>
                                       дата окончаниям (UTC)
                                    </th>
                                    <td>
                                        {dateEndStatus(licenseData?.license_expires_at, licenseData?.time_left_verbose)}
                                    </td>
                                </tr>
                                <tr>
                                    <th style={basicStylesTitles}>Сервис</th>
                                    <td>{licenseData?.license_audience}</td>
                                </tr>
                                <tr>
                                    <th style={basicStylesTitles}>
                                     Ресурсы и доступы
                                    </th>
                                    <td>{licenseData?.license_scope?.map((it) => <span key={it.id + 'scope'} style={{ whiteSpace: 'nowrap', overflow: 'hidden' }}>{it.name}{' - '}{it?.description}<br /></span>)}</td>

                                </tr>
                                <tr>
                                    <th style={basicStylesTitles}>Лимит файлов в сутки</th>
                                    <td>{licenseData?.daily_requests_limit}</td>
                                </tr>
                                <tr>
                                    <th style={basicStylesTitles}>Всего устройств</th>
                                    <td>{licenseData?.devices_count}</td>
                                </tr>
                                <tr>
                                    <th style={basicStylesTitles}>Токен</th>
                                    <td><><img width="20" className="interactive_hover" alt={'copy token'} src={CopyIcon} onClick={
                                (evt) => {
                                    evt.stopPropagation();
                                    copyBufferText(licenseData?.license_token || 'HER', 'token');
                                    // setCurrentToken(item)

                                }
                            } /> {copyText?.field==='token'? copyText?.text : ''}</></td>
                                </tr>
                               
                                
                                
                                
                            </tbody>
                        </Table>
                    }
                </Row>
                <p style={{fontSize: 18, fontWeight: 600}}>Статистика по устройствам за текущие сутки (UTC)</p>
                <Row>
                    <div className="table-responsive">
                        <table
                            className="table align-middle table-striped table-bordered"
                            id="devicesStats"
                            style={{ fontSize: 12 }}
                        >
                            <thead>
                                <tr>
                                   
                                    <th style={{}}>SN</th>
                                    <th style={{}}>FILES / REQUESTS (TODAY)</th>
                                    <th style={{}}>PLATFORM (TYPE)</th>
                                    <th style={{}}>ACTIVE</th>
                                   
                                </tr>
                            </thead>
                            <tbody>
                            
                                {licenseData?.devices?.map((item)=> 
                           
                                 
                                <tr key={item?.id + 'devices'}>  
                                    <td>{getManufactureIcon(item.type, item?.firewall?.serial_nr)}</td>
                                    <td>{getDevicesMetrics(item?.firewall?.serial_nr, item.type, licenseData?.metrics)}</td>
                                    <td>{item.firewall.platform_name}{' '}({item?.firewall?.platform_type})</td>
                                    <td><div style={{width: 16, borderRadius: '50%', background: item?.is_active ? 'green': 'red', height: 16}}></div></td>
                                  </tr>)
                                }
                            </tbody>
                        </table>
                    </div>
                </Row>
                <p style={{fontSize: 18, fontWeight: 600}}>Статистика по основным источникам за текущие сутки (UTC)</p>
                <Row>
                    <div className="table-responsive">
                        <table
                            className="table align-middle table-striped table-bordered"
                            id="checkUrlResp"
                            style={{ fontSize: 12 }}
                        >
                            <thead>
                                <tr>
                               
                                    <th style={{}}>SOURCES</th>
                                    <th style={{}}>FILES</th>
                                    <th style={{}}>REQUESTS</th>
                                    <th style={{}}>EXTRA LIMIT</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>
                                        {" "}
                                        <img
                                            width="16"
                                            style={{ marginRight: 10 }}
                                            className="interactive_hover"
                                            alt={"download sample"}
                                            src={palo}
                                          
                                        />
                                        Palo Alto Networks
                                    </td>
                                    <td>
                                        {licenseData?.metrics?.palo_alto_devices?.files || 0}
                                    </td>
                                    <td>
                                    {licenseData?.metrics?.palo_alto_devices?.requests || 0}
                                    </td>
                                    <td>{' - '}</td>
                                   
                                </tr>
                                    
                                <tr>
                                    <td>
                                        {" "}
                                        <img
                                            width="16"
                                            className="interactive_hover"
                                            alt={"download sample"}
                                            style={{ marginRight: 10 }}
                                            src={forti}
                                          
                                        />
                                        Fortinet
                                    </td>
                                    <td>
                                        {licenseData?.metrics?.fortinet_devices?.files || 0}
                                    </td>
                                    <td>
                                    {licenseData?.metrics?.fortinet_devices?.requests || 0}
                                    </td>
                                    <td>{' - '}</td>
              
                                </tr>
                                <tr>
                                    <td>
                                       
                                       BOX API
                                    </td>
                                    <td>
                                        {licenseData?.metrics?.box_api?.files || 0}
                                    </td>
                                    <td>
                                    {licenseData?.metrics?.box_api?.requests || 0}
                                    </td>
                                    <td>{licenseData?.metrics?.box_api?.extra_limit_hits || 0} / {licenseData?.metrics?.box_api?.extra_limit ||0 }</td>
                                 
                                </tr>

                                <tr>
                                    <td>
                                       
                                        CPortal
                                    </td>
                                    <td>
                                        {licenseData?.metrics?.cportal?.files || 0}
                                    </td>
                                    <td>
                                    {licenseData?.metrics?.cportal?.requests || 0}
                                    </td>
                                    <td>{licenseData?.metrics?.cportal?.extra_limit_hits || 0} / {licenseData?.metrics?.cportal?.extra_limit || 0}</td>
                                  
                                </tr>
                                <tr>
                                    <td>
                                       
                                        All sources
                                    </td>
                                    <td>
                                        {licenseData?.metrics?.files || 0}
                                    </td>
                                    <td>
                                    {licenseData?.metrics?.requests || 0}
                                    </td>
                                    <td>{' - '}</td>
                                  
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </Row>
               
            </div>
        </>
    );
}

export default LicenseDetail;
