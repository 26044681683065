import forti from '../../icons/fortinet.svg'
import palo from '../../icons/PANW.svg'
import kasper from '../../icons/kasper.svg'
import kasper_sandbox from '../../icons/product_logo.png'
import checkpoint from '../../icons/CHKP.svg'

export const fileTypeSandboxCreate= [
    {title: 'pe', key: 'pe'},
    {title: 'pdf', key: 'pdf'},
    {title: 'ms-office', key: 'ms-office'},
    {title: 'jar', key: 'jar'},
    {title: 'flash', key: 'flash'},
    {title: 'archive', key: 'archive'},
    {title: 'script', key: 'script'},
    {title: 'apk', key: 'apk'},
    {title: 'linux', key: 'linux'},
]
export function changeHandler(current, arr, set) {

    if (arr?.some((item) => item === current) !== true) {
        set([...arr, current]);
        //setSlugFilter(true)
    } else { const index = arr?.findIndex((item) => current === item); set([...arr?.slice(0, index), ...arr?.slice(index + 1)]) }
}
export const getVerdictBadgeKasper = (verdict, item) => {
    if (verdict === "Malware")
      return (
        <span
          style={{ marginRight: 10, fontSize: "x-small" }}
          className="badge bg-danger"
        >
          {verdict}
        </span>
      );
    else if (verdict === "Not applicable")
      return (
        <span
          style={{
            marginRight: 10,
            fontSize: "x-small",
            background: "#ff7a45",
          }}
          className="badge"
        >
          {verdict}
        </span>
      );
    else if (verdict === "Adware and other")
      return (
        <span
          style={{ marginRight: 10, fontSize: "x-small" }}
          className="badge bg-warning"
        >
          {verdict}
        </span>
      );
    else if (verdict === "Not categorized")
      return (
        <span
          style={{ marginRight: 10, fontSize: "x-small", background: "grey" }}
          className="badge"
        >
          {verdict}
        </span>
      );
    else if (verdict === "Clean")
      return (
        <span
          style={{ marginRight: 10, fontSize: "x-small" }}
          className="badge bg-success"
        >
          {verdict}
        </span>
      );
    else if (verdict === null)
      return (
        <span
          style={{ marginRight: 10, fontSize: "x-small" }}
          className="badge bg-light text-dark"
        >
          No data
        </span>
      );
    else if (verdict === "Unknown")
      return (
        <span
          style={{ marginRight: 10, fontSize: "x-small", background: "grey" }}
          className="badge"
        >
          {verdict}
        </span>
      );
      else if (verdict === "NotAVirus")
      return (
        <span
        style={{ marginRight: 10, fontSize: "x-small" }}
          className="badge bg-warning"
        >
          {verdict}
        </span>
      );
  };
  export const getVerdictPaBadge = (verdict, item) => {
    if (verdict === "Benign")
      return (
        <span
          style={{ marginRight: 10, fontSize: "x-small", background: "black" }}
          className="badge bg-success"
        >
          {verdict}
        </span>
      );
    else if (verdict === "Unsupported")
      return (
        <span
          style={{ marginRight: 10, fontSize: "x-small" }}
          className="badge bg-warning"
        >
          {verdict}
        </span>
      );
    else if (verdict === "Error")
      return (
        <span
          style={{
            marginRight: 10,
            fontSize: "x-small",
            background: "#9e1068",
          }}
          className="badge"
        >
          {verdict}
        </span>
      );
    else if (verdict === "Unknown")
      return (
        <span
          style={{ marginRight: 10, fontSize: "x-small", background: "grey" }}
          className="badge "
        >
          {verdict}
        </span>
      );
    else if (verdict === null)
      return (
        <span
          style={{ marginRight: 10, fontSize: "x-small" }}
          className="badge bg-light text-dark"
        >
          No data
        </span>
      );
    else
      return (
        <span
          style={{ marginRight: 10, fontSize: "x-small" }}
          className="badge bg-danger"
        >
          {verdict}
        </span>
      );
  };
  export const getVerdictBadgeForti = (verdict, item) => {
    if (verdict === "Clean")
      return (
        <span
          style={{ marginRight: 10, fontSize: "x-small" }}
          className="badge bg-success"
        >
          {verdict}
        </span>
      );
    else if (verdict === "Low Risk")
      return (
        <span
          style={{ marginRight: 10, fontSize: "x-small" }}
          className="badge bg-warning"
        >
          {verdict}
        </span>
      );
    else if (verdict === "Unknown")
      return (
        <span
          style={{ marginRight: 10, fontSize: "x-small", background: "grey" }}
          className="badge"
        >
          {verdict}
        </span>
      );
    else if (verdict === null)
      return (
        <span
          style={{ marginRight: 10, fontSize: "x-small" }}
          className="badge bg-light text-dark"
        >
          No data
        </span>
      );
    else
      return (
        <span
          style={{ marginRight: 10, fontSize: "x-small" }}
          className="badge bg-danger"
        >
          {verdict}
        </span>
      );
  };
  export const getVerdictTVBadge = (verdict, item) => {
    if (verdict === "Benign")
      return (
        <span
          style={{ marginRight: 10, fontSize: "x-small", background: "black" }}
          className="badge bg-success"
        >
          {verdict}
        </span>
      );
    else if (verdict === "Unknown")
      return (
        <span
          style={{ marginRight: 10, fontSize: "x-small", background: "grey" }}
          className="badge "
        >
          {verdict}
        </span>
      );
    else if (verdict === null)
      return (
        <span
          style={{ marginRight: 10, fontSize: "x-small" }}
          className="badge bg-light text-dark"
        >
          No data
        </span>
      );
    else
      return (
        <span
          style={{ marginRight: 10, fontSize: "x-small" }}
          className="badge bg-danger"
        >
          {verdict}
        </span>
      );
  };
  export const getVerdictKATAPBadge = (verdict, item) => {
    if (verdict === "Clean")
      return (
        <span
          style={{ marginRight: 10, fontSize: "x-small", background: "black" }}
          className="badge bg-success"
        >
          {verdict}
        </span>
      );
    else if (verdict === "Unknown")
      return (
        <span
          style={{ marginRight: 10, fontSize: "x-small", background: "grey" }}
          className="badge "
        >
          {verdict}
        </span>
      );
    else if (verdict === null)
      return (
        <span
          style={{ marginRight: 10, fontSize: "x-small" }}
          className="badge bg-light text-dark"
        >
          No data
        </span>
      );
      else if (verdict === 'Grayware')
      return (
        <span
          style={{ marginRight: 10, fontSize: "x-small" }}
          className="badge bg-warning"
        >
          Grayware
        </span>
      );
    else
      return (
        <span
          style={{ marginRight: 10, fontSize: "x-small" }}
          className="badge bg-danger"
        >
          {verdict}
        </span>
      );
  };
  export const getSeverityBadge = (severity, item) => {
    if (severity=== 0)
      return (
        <span
          key={item.cat_id}
          style={{ fontSize: "x-small", background: "black" }}
          className="badge bg-success"
        >
          {item?.name}
        </span>
      );
    else if (severity=== 1)
      return (
        <span
        key={item.cat_id}
          style={{ marginRight: 10, fontSize: "x-small" }}
          className="badge bg-warning"
        >
          {item.name}
        </span>
      );
    else if (severity === 2)
      return (
        <span
        key={item.cat_id}
          style={{
            
            fontSize: "x-small",
   
          }}
          className="badge bg-danger"
        >
     {item.name}
        </span>
      );
   
  };
  export const sortFileTypeS=(arr, order)=> {
    if (arr) {
   
      const sortedArray = arr.sort((a,b)=>  order? b.localeCompare(a): a.localeCompare(b));
      return sortedArray
    }
    else return []
  }
  export const manualFilter = (arr, producer, fileType, online, ordering, setArr) => {
    let filterProducer = []  
    if (producer) {
      filterProducer = arr.filter((item)=> item.type === producer)
    }
    else filterProducer = arr.slice()
    let filterType= []

    if (fileType) filterType = filterProducer?.filter((item)=> { return item.supported_file_types?.includes(fileType)})
    else filterType = filterProducer.slice()
  let onlineFilter = []
  if (online) onlineFilter = filterType.filter((item)=> {return getStatus(item.status, item.type, item.is_active)}) 
  else onlineFilter = filterType.slice()

let queueArr= []
if (ordering) {
  if (ordering[0]==='-')  queueArr = onlineFilter?.sort((a, b)=> ordering?.includes('queue')? b[ordering.replace('-','')]- a[ordering.replace('-','')] :b[ordering.replace('-','')].localeCompare(a[ordering.replace('-','')]) )
  else queueArr = onlineFilter?.sort((a, b)=> ordering?.includes('queue')?a[ordering.replace('-','')]- b[ordering.replace('-','')]: a[ordering.replace('-','')].localeCompare(b[ordering.replace('-','')]))

} else queueArr = onlineFilter.slice();
setArr(queueArr)
  }

  const getStatus=(status, type, active)=> {
    if (type==="kaspersky") return true
    if (type==="kaspersky_sandbox") {
      return active
    }
    if (status?.status===200) return true
    return false
  }

export const getReverseDate=(date)=> {
  if (date) {
    return date?.split(' ')[0].split('.').reverse().join('-')
  } else return null
}

export const getManufactureNameWithIcon=(type)=> {
  let name= ''
switch(type) {
  case('palo_alto'): name = <span style={{  background: "#a8d8ff" }}className="badge "><img
  style={{background: 'transparent',marginRight: 5,}}
              width="12"
              className="interactive_hover"
              alt={"manufacture"}
              src={palo}
              disabled
             
          />Palo Alto Networks</span>; break;
  case('fortinet'): name = <span style={{  background: "#a8d8ff" }}className="badge "><img
  style={{background: 'transparent', marginRight: 5,}}
              width="12"
              className="interactive_hover"
              alt={"manufacture"}
              src={forti}
              disabled
             
          />Fortinet</span>;break;
  case('checkpoint'): name = <span style={{   background: "#a8d8ff" }}className="badge "><img
  style={{background: 'transparent',marginRight: 5,}}
              width="12"
              className="interactive_hover"
              alt={"manufacture"}
              src={checkpoint}
              disabled
             
          />CheckPoint</span>;break;

}
return name
}

export const dateEndStatus = (date, status) => {
if (status){
  if (status.includes('дн') && (Number(status.split(' ')[0]) === 0)) return <span style={{  fontSize: 'x-small' }} className="badge bg-danger">{date} ({status})</span>;
  else if (status.includes('дн') && (Number(status.split(' ')[0]) <= 31)) return <span style={{  fontSize: 'x-small' }} className="badge bg-warning">{date} ({status})</span>;
  else return <span style={{  fontSize: 'x-small' }} className="badge bg-success">{date} ({status})</span>
}else return ''
}
export const getManufactureName=(type)=> {
  let name= ''
 
switch(type) {
  case('palo_alto'): name = 'Palo Alto Networks'; break;
  case('fortinet'): name = 'Fortinet';break;
  case('checkpoint'): name = "CheckPoint";break;

}
return name
}
export const getManufactureIcon=(type, sn)=> {
  let name = ''
    switch(type) {
      case('palo_alto'): name =<span><img
      style={{background: 'transparent',marginRight: 5,}}
                  width="12"
                  className="interactive_hover"
                  alt={"manufacture"}
                  src={palo}
                  disabled
                 
              />{sn}</span>; break;
      case('fortinet'): name = <span ><img
      style={{background: 'transparent', marginRight: 5,}}
                  width="12"
                  className="interactive_hover"
                  alt={"manufacture"}
                  src={forti}
                  disabled
                 
              />{sn}</span>;break;
      case('checkpoint'): name = <span><img
      style={{background: 'transparent',marginRight: 5,}}
                  width="12"
                  className="interactive_hover"
                  alt={"manufacture"}
                  src={checkpoint}
                  disabled
                 
              />{sn}</span>;break;
      default: name = sn; break;
    }
  
  return name
}
export const getDevicesMetrics=(sn, type, metrics)=> {
  if (type) {

    let arrName= type + '_devices';
let metricsFilesRequests = metrics[arrName]?.devices?.find(item=>item.source_id === sn);
if (!metricsFilesRequests) return <span>0 / 0</span>
else return <span>{String(metricsFilesRequests?.files) || '0'} / {String(metricsFilesRequests?.requests)|| '0'}</span>
  } else {
    let metricsFilesRequests = metrics.palo_alto_devices?.devices?.find(item=>item.source_id === sn);
    if (!metricsFilesRequests) metricsFilesRequests = metrics.fortinet_devices?.devices?.find(item=>item.source_id === sn);
    if (!metricsFilesRequests) return <span>0 / 0</span>
    else return <span>{String(metricsFilesRequests?.files) || '0'} / {String(metricsFilesRequests?.requests)|| '0'}</span>
  }
}
export const getSource=(source)=> {
  let sourceName = ''
  switch(source) {
    case('00'): sourceName = 'Palo Alto Firewall'; break;
    case('01'): sourceName = 'Box API'; break;
    case('02'): sourceName = 'CPortal'; break;
default: sourceName = "Forti Gateway"; break;
  }
  return sourceName
}