import React, { useState, useEffect, useRef, useContext } from "react";
import Col from "react-bootstrap/esm/Col";
import Row from "react-bootstrap/esm/Row";
import { AuthContext } from "../../context";
import SandboxService from "../../API/SandboxService";
import Button from "react-bootstrap/esm/Button";
import Preloader from "../UI/Preloader/Preloader";
import { Alert,  Table } from "react-bootstrap";
import downloadPdf from "../../icons/pdf.svg";
import {
    getSeverityBadge,
    getVerdictBadgeForti,
    getVerdictBadgeKasper,
    getVerdictKATAPBadge,
    getVerdictPaBadge,
    getVerdictTVBadge,
} from "./utils";
import forti from "../../icons/fortinet.svg";
import palo from "../../icons/PANW.svg";
import kasper from "../../icons/kasper.svg";
import kasper_sandbox from "../../icons/product_logo.png";
import MyConfirm from "../UI/MyConfirm/MyConfirm";
import UrlService from "../../API/UrlService";
import kasper_atap from '../../icons/rs-b-kata.png'
function RequestsDetail({ sha256, setviewFileDetail, url, ip }) {
    const [sandboxData, setSandboxData] = useState(null);
    const [isPreloader, setIsPreloader] = useState(false);
    const [isPreloaderSeverityUrl, setIsPreloaderSeverityUrl] = useState(false);
    const [isPreloaderSeverityIp, setIsPreloaderSeverityIp] = useState(false);
    const [copytext, setCopyText] = useState(false);
    const [errors, setErrors] = useState(null);
    const [switchFalsePositive, setSwitchFalsePositive] = useState(false);
    const [falsePositive, setFalsePositive] = useState(false);
    const [successPatch, setSuccessPatch] = useState(false);
    const [severityDataUrl, setSeverityDataUrl] = useState(null);
    const [severityDataIp, setSeverityDataIp] = useState(null);

    //контекст
    const { setStatus } = useContext(AuthContext);
    const changeStatus = () => setStatus(true);

    const basicStylesTitles = {
        fontSize: "smaller",
        width: "30%",
        textAlign: "right",
    };
    const fetchData = async () => {
        setIsPreloader(true);

        await SandboxService.getRequestStatsDetail(sha256)
            .then((res) => {
                setSandboxData(res);
                setFalsePositive(res.false_positive);
                if (res.file_url) setSeverityDataUrl(res.file_url)
                if (res.file_ip) setSeverityDataIp(res.file_ip)
            })

            .catch(() => changeStatus())
            .finally(() => setIsPreloader(false));
    };
    const fetchDataSeveretityUrl = async (url) => {
        setIsPreloaderSeverityUrl(true);
        await UrlService.checkUrl(url)
            .then((res) => {
                setSeverityDataUrl(res);
                
            })

            .catch(() => changeStatus())
            .finally(() => setIsPreloaderSeverityUrl(false));
    };
    const fetchDataSeveretityIP = async (ip) => {
        setIsPreloaderSeverityIp(true);

        await UrlService.checkUrl(ip)
            .then((res) => {
                setSeverityDataIp(res);
                
            })

            .catch(() => changeStatus())
            .finally(() => setIsPreloaderSeverityIp(false));
    };

    useEffect(() => {
        if (sha256) {
            fetchData();
        }
    }, [sha256, successPatch, ]);
    useEffect(()=> {
        if (sandboxData) {
            if (sandboxData?.file_url && (sandboxData?.file_url!=='unknown')) fetchDataSeveretityUrl(sandboxData?.file_url)
            else setIsPreloaderSeverityUrl(null)
            
            if (sandboxData?.file_ip)   fetchDataSeveretityIP(sandboxData?.file_ip.slice(0, sandboxData?.file_ip.indexOf(":")))
            else setIsPreloaderSeverityIp(null)

        }
    },[ sandboxData])

    const dateStyles = {
        fontSize: "14px",
    };
    const changeDate = (date) => {
        if (date) {
            const formatDate = date?.split("T");
            const reverseDate = (
                <>
                    <span>{formatDate[0].split("-").reverse().join(".")}</span>{" "}
                    <span>{formatDate[1].split(".")[0]}</span>
                </>
            );

            return reverseDate;
        } else return null;
    };
    const getVerdictBadge = (verdict, item) => {
        if (verdict === "Clean")
            return (
                <span
                    style={{
                        marginRight: 10,
                        fontSize: "x-small",
                        background: "black",
                    }}
                    className="badge bg-success"
                >
                    {verdict}
                </span>
            );
        else if (verdict === "Unsupported" || verdict === "Grayware")
            return (
                <span
                    style={{ marginRight: 10, fontSize: "x-small" }}
                    className="badge bg-warning"
                >
                    {verdict}
                </span>
            );
        else if (verdict === "Error")
            return (
                <span
                    style={{
                        marginRight: 10,
                        fontSize: "x-small",
                        background: "#9e1068",
                    }}
                    className="badge"
                >
                    {verdict}
                </span>
            );
        else if (verdict === "Unknown")
            return (
                <span
                    style={{
                        marginRight: 10,
                        fontSize: "x-small",
                        background: "grey",
                    }}
                    className="badge "
                >
                    {verdict}
                </span>
            );
        else if (verdict === null)
            return (
                <span
                    style={{ marginRight: 10, fontSize: "x-small" }}
                    className="badge bg-light text-dark"
                >
                    No data
                </span>
            );
        else
            return (
                <span
                    style={{ marginRight: 10, fontSize: "x-small" }}
                    className="badge bg-danger"
                >
                    {verdict}
                </span>
            );
    };
    const getStatusBadge = (status, item) => {
        let element = (
            <span
                style={{ marginRight: 10, fontSize: "x-small" }}
                className="badge bg-light text-dark"
            >
                No data
            </span>
        );
        switch (status) {
            case "accepted":
                element = (
                    <span
                        style={{
                            marginRight: 10,
                            fontSize: "x-small",
                            background: "grey",
                        }}
                        className="badge"
                    >
                        Accepted
                    </span>
                );
                break;
            case "stage_01":
                element = (
                    <span
                        style={{ marginRight: 10, fontSize: "x-small" }}
                        className="badge bg-warning"
                    >
                        Stage 1
                    </span>
                );
                break;
            case "stage_02":
                element = (
                    <span
                        style={{ marginRight: 10, fontSize: "x-small" }}
                        className="badge bg-warning"
                    >
                        Stage 2
                    </span>
                );
                break;
            case "stage_03":
                element = (
                    <span
                        style={{ marginRight: 10, fontSize: "x-small" }}
                        className="badge bg-warning"
                    >
                        Stage 3
                    </span>
                );
                break;
            case "succeeded":
                element = (
                    <span
                        style={{ marginRight: 10, fontSize: "x-small" }}
                        className="badge bg-success"
                    >
                        Succeeded
                    </span>
                );
                break;
            case "finished":
                element = (
                    <span
                        style={{ marginRight: 10, fontSize: "x-small" }}
                        className="badge bg-success"
                    >
                        Finished
                    </span>
                );
                break;
            case "timeout":
                element = (
                    <span
                        style={{
                            marginRight: 10,
                            fontSize: "x-small",
                            background: "#ff7a45",
                        }}
                        className="badge"
                    >
                        Timeout
                    </span>
                );
                break;
            case "failed":
                element = (
                    <span
                        style={{ marginRight: 10, fontSize: "x-small" }}
                        className="badge bg-danger"
                    >
                        Failed
                    </span>
                );
                break;
        }
        return element;
    };

    const copyBufferText = ({ text, field }) => {
        navigator.clipboard
            .writeText(text)
            .then((res) => {
                setCopyText({ text: "Copy", field: field });
            })
            .catch((err) => {
                setCopyText({ text: "Error", field: field });
            });
    };
    const switchFalsePositiveFunction = async () => {

        setIsPreloader(true);
        let param = !sandboxData?.false_positive
        await SandboxService.switchFalsePositive(sandboxData.sha256, param)
            .then(() => { setIsPreloader(false); setSwitchFalsePositive(false); setSuccessPatch(!successPatch) })
            .catch((e) => {
             setIsPreloader(false); setErrors(typeof e.message === "object" ? e.message : ["Ошибка переключения"]) })
        ;
    }
    const downloadFile = async (item, type) => {
        setIsPreloader(true);
        await SandboxService.getExportSampleFileSandbox(item?.sha256)
            .then((resp) => {
                setErrors(null);
                const date = new Date()
                    .toISOString()
                    .replace(".", "-")
                    .split("T")[0];

                const effectiveFileName = `${item.sha256}-${date}`;
                const url = window.URL.createObjectURL(
                    new Blob([resp.data], {
                        type: resp.headers["content-type"],
                    })
                );
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", effectiveFileName);
                document.body.appendChild(link);
                link.click();
                link.remove();
            })
            .catch((err) => {
                setErrors(
                    err.message?.length > 0 ? err.message : ["Не найдено"]
                );
            })
            .finally(() => setIsPreloader(false));
    };
    const downloadReport = async (item, type = "pdf", producer = "palo") => {
        setIsPreloader(true);
        await SandboxService.getExportFileSandbox(item?.sha256, type, producer)
            .then((resp) => {
                setErrors(null);
                const date = new Date()
                    .toISOString()
                    .replace(".", "-")
                    .split("T")[0];

                const effectiveFileName = `${item.sha256}-${date}`;
                const url = window.URL.createObjectURL(
                    new Blob([resp.data], {
                        type: resp.headers["content-type"],
                    })
                );
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", effectiveFileName);
                document.body.appendChild(link);
                link.click();
                link.remove();
            })
            .catch((err) => {
                setErrors(
                    err.message?.length > 0 ? err.message : ["Не найдено"]
                );
            })
            .finally(() => setIsPreloader(false));
    };

    return (
        <>
         {switchFalsePositive && <MyConfirm visible={switchFalsePositive} setVisible={setSwitchFalsePositive} >
                <Row className="mb-4" style={{ position: "relative" }}>
                    {isPreloader && <Preloader leftCoord={true} />}
                    <h3 className="mb-4 mt-4">Вы точно хотите изменить статус FALSE POSITIVE?</h3>
                </Row>
                <Row> {errors?.length > 0 &&
                    errors?.map(error =>
                        <Alert key="danger" variant="danger">
                            {error}
                        </Alert>
                    )
                }</Row>
                <Row>
                    <Col >
                        <Button variant="outline-primary" size="sm" type="submit " style={{ width: '100%' }} onClick={(e) => { e.preventDefault(); switchFalsePositiveFunction() }}>
                            Да
                        </Button>

                    </Col>
                    <Col >
                        <Button variant="outline-danger" size="sm" onClick={(e) => { e.preventDefault(); setSwitchFalsePositive(false) ;setErrors([]) }} style={{ width: '100%' }}>
                            нет
                        </Button>
                    </Col>
                </Row>
            </MyConfirm>}
            <div
                style={{
                    width: "900px",
                    margin: "0 auto",
                    maxHeight: "90vh",
                    height: 900,
                }}
            >
                <div
                    style={{
                        position: "relative",
                        display: "flex",
                        justifyContent: "space-between",
                        marginBottom: "5px",
                    }}
                >
                    <h5>{sandboxData?.file_name || ""}</h5>
                    <Button
                        variant="outline-primary"
                        onClick={() => setviewFileDetail(false)}
                    >
                        Закрыть
                    </Button>
                </div>
                <Row style={{ margin: "25px 0 0", position: "relative" }}>
                    {isPreloader && <Preloader />}
                    {
                        <Table
                            bordered
                            striped
                            hover
                            id="DetailFile"
                            style={{ marginBottom: 20, fontSize: 14 }}
                        >
                            <tbody>
                                <tr>
                                    <th style={basicStylesTitles}>SHA 256</th>
                                    <td style={{ fontSize: 12 }}>
                                        {sandboxData?.sha256}
                                    </td>
                                </tr>
                                <tr>
                                    <th style={basicStylesTitles}>
                                        PARUS VERDICT
                                    </th>
                                    <td>
                                        {getVerdictBadge(sandboxData?.verdict)}
                                    </td>
                                </tr>
                                <tr>
                                    <th style={basicStylesTitles}>
                                        FIRST SEEN (UTC)
                                    </th>
                                    <td>
                                        {changeDate(sandboxData?.first_seen)}
                                    </td>
                                </tr>
                                <tr>
                                    <th style={basicStylesTitles}>
                                        LAST SEEN (UTC)
                                    </th>
                                    <td>
                                        {changeDate(sandboxData?.last_seen)}
                                    </td>
                                </tr>
                                <tr>
                                    <th style={basicStylesTitles}>HITS</th>
                                    <td>{sandboxData?.hits}</td>
                                </tr>
                                <tr>
                                    <th style={basicStylesTitles}>
                                        FILE SIZE 
                                    </th>
                                    <td>{sandboxData?.file_size}{' '}{sandboxData?.file_size && 'байт'}</td>

                                </tr>
                                <tr>
                                    <th style={basicStylesTitles}>FILE TYPE</th>
                                    <td>{sandboxData?.file_type}</td>
                                </tr>
                                <tr>
                                    <th style={basicStylesTitles}>FILE NAME</th>
                                    <td>{sandboxData?.file_name}</td>
                                </tr>
                                <tr>
                                    <th style={basicStylesTitles}>
                                        SOURCE URL
                                    </th>
                                    <td style={{position: "relative"}}>
                                        {isPreloaderSeverityUrl?<Preloader mini={true}/>:<><div
                                            style={{
                                                wordWrap: "break-word",
                                                width: 550,
                                            }}
                                        >
                                            {sandboxData?.file_url}
                                        </div>
                                        <div style={{display: "flex", gap: 5, marginTop: 5}}>{severityDataUrl?.categories?.map((item)=> getSeverityBadge(item.severity, item))}{severityDataUrl?.kfeed_results?.length>0 &&  <span
          style={{ marginRight: 10, fontSize: "x-small" }}
          className="badge bg-danger"
        >
          {'KFEED'}
        </span>}</div></>}
                                    </td>
                                </tr>
                                <tr>
                                    <th style={basicStylesTitles}>SOURCE IP</th>
                                    <td style={{position: "relative"}}>{isPreloaderSeverityIp?<Preloader mini={true}/>: <>{sandboxData?.file_ip}
                                    <div style={{display: "flex", gap: 5, marginTop: 5}}>{severityDataIp?.categories?.map((item)=> getSeverityBadge(item.severity, item))}{severityDataIp?.kfeed_results?.length>0 &&  <span
          style={{ marginRight: 10, fontSize: "x-small" }}
          className="badge bg-danger"
        >
          {'KFEED'}
        </span>}</div>
  </>}</td>
                                </tr>
                                <tr>
                                    <th style={basicStylesTitles}>
                                        DOWNLOAD SAMPLE (ZIP)
                                    </th>
                                    <td>
                                        {sandboxData?.file_sample ? (
                                            <Button
                                                variant="primary"
                                                style={{
                                                    fontSize: "10px",
                                                    padding: 3,
                                                    width: 80,
                                                    height: 30,
                                                }}
                                                onClick={() =>
                                                    downloadFile(
                                                        sandboxData,
                                                        "zip"
                                                    )
                                                }
                                            >
                                                DOWNLOAD
                                                {/* <img
                                                        width="16"
                                                        className="interactive_hover"
                                                        alt={"download sample"}
                                                        src={downloadZip}
                                                        disabled
                                                    /> */}
                                            </Button>
                                        ) : (
                                            " - "
                                        )}
                                    </td>
                                </tr>
                                <tr>
                                    <th style={basicStylesTitles}>
                                        FALSE POSITIVE
                                    </th>
                                    <td>
                                        {" "}
                                        {sandboxData?.verdict!=='Clean' && <div className="form-check form-switch">
                                            <input
                                                className="form-check-input"
                                                type="checkbox"
                                                id="flexSwitch"
                                                checked={!!sandboxData?.false_positive}
                                                onChange={(
                                                    e
                                                ) => {
                                                    setSwitchFalsePositive(
                                                        true
                                                    );
                                                }}
                                            />
                                        </div>}
                                    </td>
                                </tr>
                            </tbody>
                        </Table>
                    }
                </Row>
                <Row>
                    <div className="table-responsive">
                        <table
                            className="table align-middle table-striped table-bordered"
                            id="checkUrlResp"
                            style={{ fontSize: 12 }}
                        >
                            <thead>
                                <tr>
                                    <th style={{width: '30%'}}> </th>
                                    <th style={{}}>Verdict</th>
                                    <th style={{}}>Check Date</th>
                                    <th style={{}}>Details</th>
                                    <th style={{}}>Report</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>
                                        {" "}
                                        <img
                                            width="16"
                                            style={{ marginRight: 10 }}
                                            className="interactive_hover"
                                            alt={"download sample"}
                                            src={palo}
                                            disabled
                                        />
                                        Palo Alto Networks
                                    </td>
                                    <td>
                                        {getVerdictPaBadge(
                                            sandboxData?.verdict_palo_alto
                                        )}
                                    </td>
                                    <td>
                                        {changeDate(
                                            sandboxData?.finished_palo_alto_at
                                        )}
                                    </td>
                                    <td> {sandboxData?.details_palo_alto
                                            ? sandboxData?.details_palo_alto
                                                  ?.split("\n")
                                                  ?.map((item) => (
                                                      <div key={item + 'detailsPA'}>{item}</div>
                                                  ))
                                            : " - "}</td>
                                    <td>
                                        {sandboxData?.report_palo_alto && (
                                            <Button
                                                variant="light"
                                                style={{
                                                    fontSize: "10px",
                                                    padding: 3,
                                                }}
                                                onClick={() =>
                                                    downloadReport(
                                                        sandboxData,
                                                        "pdf"
                                                    )
                                                }
                                            >
                                                <img
                                                    width="16"
                                                    className="interactive_hover"
                                                    alt={"download sample"}
                                                    src={downloadPdf}
                                                    disabled
                                                />
                                            </Button>
                                        )}
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        {" "}
                                        <img
                                            width="16"
                                            className="interactive_hover"
                                            alt={"download sample"}
                                            src={palo}
                                            style={{ marginRight: 10 }}
                                            disabled
                                        />
                                        Threat Vault
                                    </td>
                                    <td>
                                        {getVerdictTVBadge(
                                            sandboxData?.verdict_threat_vault
                                        )}{" "}
                                    </td>
                                    <td>
                                        {changeDate(
                                            sandboxData?.finished_threat_vault_at
                                        )}
                                    </td>
                                    <td>
                                        {sandboxData?.details_threat_vault
                                            ? sandboxData?.details_threat_vault
                                                  ?.split("\n")
                                                  ?.map((item) => (
                                                      <div key={item + 'detailsTV'}>{item}</div>
                                                  ))
                                            : " - "}
                                    </td>
                                    <td></td>
                                </tr>
                                <tr>
                                    <td>
                                        {" "}
                                        <img
                                            width="16"
                                            className="interactive_hover"
                                            alt={"download sample"}
                                            style={{ marginRight: 10 }}
                                            src={forti}
                                            disabled
                                        />
                                        Fortinet
                                    </td>
                                    <td>
                                        {getVerdictBadgeForti(
                                            sandboxData?.verdict_fortisandbox
                                        )}{" "}
                                    </td>
                                    <td>
                                        {changeDate(
                                            sandboxData?.finished_fortisandbox_at
                                        )}
                                    </td>
                                    <td>  {sandboxData?.details_fortisandbox
                                            ? sandboxData?.details_fortisandbox
                                                  ?.split("\n")
                                                  ?.map((item) => (
                                                      <div key={item + 'detailsFR'}>{item}</div>
                                                  ))
                                            : " - "}</td>
                                    <td>
                                        {sandboxData?.report_fortisandbox && (
                                            <Button
                                                variant="light"
                                                style={{
                                                    fontSize: "10px",
                                                    padding: 3,
                                                }}
                                                onClick={() =>
                                                    downloadReport(
                                                        sandboxData,
                                                        "pdf",
                                                        "forti"
                                                    )
                                                }
                                            >
                                                <img
                                                    width="16"
                                                    className="interactive_hover"
                                                    alt={"download sample"}
                                                    src={downloadPdf}
                                                    disabled
                                                />
                                            </Button>
                                        )}
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        {" "}
                                        <img
                                            width="16"
                                            className="interactive_hover"
                                            alt={"download sample"}
                                            style={{ marginRight: 10 }}
                                            src={kasper}
                                            disabled
                                        />
                                        Kaspersky TIP Threat Lookup
                                    </td>
                                    <td>
                                        {getVerdictBadgeKasper(
                                            sandboxData?.verdict_ktip_hash_lookup
                                        )}
                                    </td>
                                    <td>
                                        {changeDate(
                                            sandboxData?.finished_ktip_hash_lookup_at
                                        )}
                                    </td>
                                    <td> {sandboxData?.details_ktip_hash_lookup
                                            ? sandboxData?.details_ktip_hash_lookup
                                                  ?.split("\n")
                                                  ?.map((item) => (
                                                      <div key={item + 'detailsKT'}>{item}</div>
                                                  ))
                                            : " - "} </td>
                                    <td></td>
                                </tr>

                                <tr>
                                    <td>
                                        {" "}
                                        <img
                                            width="16"
                                            className="interactive_hover"
                                            alt={"download sample"}
                                            style={{ marginRight: 10 }}
                                            src={kasper_atap}
                                            disabled
                                        />
                                        Kaspersky ATAP
                                    </td>
                                    <td>
                                        {getVerdictKATAPBadge(
                                            sandboxData?.verdict_kaspersky_atap
                                        )}
                                    </td>
                                    <td>
                                        {changeDate(
                                            sandboxData?.finished_kaspersky_atap_at
                                        )}
                                    </td>
                                    <td> {sandboxData?.details_kaspersky_atap
                                            ? sandboxData?.details_kaspersky_atap
                                                  ?.split("\n")
                                                  ?.map((item) => (
                                                      <div key={item + 'detailsKS'}>{item}</div>
                                                  ))
                                            : " - "} </td>
                                    <td></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </Row>
            </div>
        </>
    );
}

export default RequestsDetail;
