import React, { useState, useEffect, useMemo, useContext } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import QuotaService from "../../API/QuotaService";

import Alert from "react-bootstrap/Alert";
import Preloader from "../UI/Preloader/Preloader";
import { AuthContext } from "../../context";
import Auth from "../../API/Auth";

import ImgRemove from '../../icons/remove.png';
function ChangeDocumentQuotaForm ({ close, type, currentFile, quotaId, renew }) {

    const [isPreloader, setIsPreloader] = useState(false);
    const [file, setFile] = useState(false);
    const [errors, setErrors] = useState(false);
    const [fileCurrentTitle, setFileCurrentTitle] = useState(currentFile)
    //контекст
    const { setStatus } = useContext(AuthContext);
    const changeStatus = () => setStatus(true);

    const refreshToken = async () => {
        await Auth.refreshToken(localStorage.getItem("freshToken"))
            .then((res) => {
                localStorage.setItem("token", res.data.access);
                setErrors([]);
                changeDocQuotas();
            })
            .catch(() => changeStatus());
    };

    const changeDocQuotas = async (event) => {
        if (event) event.preventDefault();
        const formData = new FormData();
        if (file) formData.append('co_doc', file)
        else formData.append('co_doc', '')
           
            setIsPreloader(true)
            await QuotaService.addDocumentQuota(formData, quotaId).then((resp)=>{renew();
                close()
            }).catch((err) => { setErrors(err.message)}).finally(()=> setIsPreloader(false));
        }

    return (
        <Form onSubmit={changeDocQuotas} style={{ position: "relative" }}>
            {isPreloader && <Preloader />}

            {errors.length > 0 &&
                errors.map((error) => (
                    <Row>
                        <Col>
                            <Alert key="danger" variant="danger">
                                {error}
                            </Alert>
                        </Col>
                    </Row>
                ))}

            <Row className="mb-4">
                <h5 style={{ fontSize: "26px" }}>{type==='add'? 'Добавить документ' : "Изменить документ"}</h5>
            </Row>
           { fileCurrentTitle&& <Row className="mb-4">
            <Col className="col-12">
                <span>Текущий файл: <div style={{display: "flex", justifyContent: "space-between"}}><span style={{fontWeight: 600}}>{currentFile?.split('/')[currentFile?.split('/')?.length -1]}</span> <div style={{width: 25, height: 25}}><img width="20" style={{ cursor: "pointer"}} alt="удалить" src={ImgRemove} disabled onClick={
                        () => {
                           setFileCurrentTitle(false)
                        }
                    } />
                    </div></div> </span></Col>
                <Col className="col-2">
               </Col>
            </Row>}
            <Row className="mb-4">
            <Col className="mb-3 col-12">
                    <Form.Group className="mb-4">
                        <Form.Label size="sm">Файл</Form.Label>
                        <Form.Control
                            type="file"
                           // size="sm"
                            style={{ width: '100%' }}
                            onChange={(e) => {setFile(e.target.files[0])}}
                        // onChange={(e) => { validateFiles(e.target.files[0], index) }}
                        // setFiles([...files, { file: e.target.files, name: nameFile(index,), field: index }]) 
                        accept="/*, .doc, .docx, .xls, .xlsx, .txt, .pdf, .csv"
                        />

                    <Form.Text className="text-muted">
                    Формат (.doc, .docx, .xls, .xlsx, .txt, .pdf, .csv)
                        </Form.Text>
                    </Form.Group>
                </Col>
            </Row>
            <Row >
                <Col className="mb-3 col-5" >
                    <Form.Group className="mb-4"  >
                        <Button style={{ width: '100%' }} variant="outline-danger" size="sm" onClick={() => {
                            close();

                        }}>
                            Отменить
                        </Button>
                    </Form.Group>
                </Col>
                <Col className="mb-3 col-5" >
                    <Form.Group className="mb-4">
                        <Button style={{ width: '100%' }} variant="outline-primary" size="sm" type="submit" >
                            Сохранить
                        </Button><br />
                    </Form.Group>
                </Col>
            </Row>
        </Form>
    );
}
export default ChangeDocumentQuotaForm ;
