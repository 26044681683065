import React, { useState, useEffect, useRef, useContext } from "react";
import Col from "react-bootstrap/esm/Col";
import Row from "react-bootstrap/esm/Row";
import ImgRemove from "../../icons/remove.png";
import Auth from "../../API/Auth";
import MyConfirm from "../UI/MyConfirm/MyConfirm";
import { AuthContext } from "../../context";
import Alert from "react-bootstrap/Alert";
import SandboxService from "../../API/SandboxService";
import Button from "react-bootstrap/esm/Button";
import Preloader from "../UI/Preloader/Preloader";
import CopyIcon from '../../icons/copy.svg';
import ImgEdit from "../../icons/edit.png";
import AddLicenseForm from "./AddLicenseForm";
import PatchLicenseForm from "./PatchLicenseForm";
import { dateEndStatus } from "./utils";
import LicenseDetail from "./LicenseDetail";
import MyModalFeed from "../UI/MyModalFeed/MyModalFeed";
function LicenseList() {
    const [currentItem, setCurrentItem] = useState(null);

    const [isPreloader, setIsPreloader] = useState(false);
    const [copytext, setCopyText] = useState(false);
    const [deleteItem, setDeleteItem] = useState(false);
    const [switchActive, setSwitchActive] = useState(false);
    const [addLicense, setAddLicense] = useState(false);
    const [patchSandbox, setPatchSandbox] = useState(false);
    const [licensesData, setLicensesData] = useState(null);
    const [dataCount, setDataCount] = useState(0);

    const [isAssetLoading, setIsAssetLoading] = useState(false);
    const [nextPage, setNextPage] = useState(0);
    const [needToLoad, setNeedToLoad] = useState(false);
    const lastItem = useRef();
    const observer = useRef();
    const [errors, setErrors] = useState([]);
    const [sandboxDataTable, setSandboxDataTable] = useState(null);
    const [viewDetail, setViewDetail] = useState(false);
    const [producerFilter, setProducerFilter] = useState(false);
    const [typeFilter, setTypeFilter] = useState(false)
    const [filteredArray, setFilteredArray] = useState(null);
    const [onlineFilter, setOnlineFilter] = useState(false);
    const [ordering, setOrdering] = useState('-files_in_queue');
    //контекст
    const { setStatus } = useContext(AuthContext);
    const changeStatus = () => setStatus(true);
    const sandboxArray = filteredArray || sandboxDataTable || [];

    const fetchData = async (add) => {
        let filters = false;
        // if (searchText || activeFilter) {
        //     filters = {
        //         search: searchText,
        //         is_active: activeFilter,
        //     };
        // }

        setIsPreloader(true);
        setIsAssetLoading(true);
        // await SandboxService.getSandboxesBalancer().then((resp)=> setSandboxBalancerData(resp));
        await SandboxService.getLicensesList(filters, add ? nextPage : 1)
            .then((res) => {
                if (add === "add") {
                    res.results?.map((item) =>
                        setLicensesData((arr) => [...arr, item])
                    );
                    setNextPage(res.next ? nextPage + 1 : null);
                } else {
                    setLicensesData(res.results);
                    setNextPage(res.next ? 2 : null);
                    setDataCount(res.count);
                
                // setNextPage(res.next ? nextPage + 1 : null);
                setIsAssetLoading(false);
            }})
            .catch(() => changeStatus())
            .finally(() => setIsPreloader(false));
    };
    useEffect(() => {
        if (
            !deleteItem &&
            !switchActive &&
            !addLicense &&
            !switchActive &&
            !patchSandbox
        ) {
            setIsPreloader(true);
            const timerRefresh = setTimeout(fetchData, 1000);
            return () => {
                clearTimeout(timerRefresh);
            };
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        deleteItem,
        addLicense,
        switchActive,
        patchSandbox,
    ]);


    useEffect(() => {
        (async () => {
            if (needToLoad && nextPage) {
                await fetchData("add");
                setNeedToLoad(false);
            }
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [needToLoad]);

    useEffect(() => {
        if (isAssetLoading) return;
        if (observer.current) observer.current.disconnect();
        var callback = function (entries, observer) {
            if (
                entries[0].isIntersecting &&
                nextPage &&
                licensesData?.length > 0
            ) {
                setNeedToLoad(true);
            }
        };
        observer.current = new IntersectionObserver(callback);
        observer.current.observe(lastItem.current);
    }, [isAssetLoading]);
    const dateStyles = {
        fontSize: "x-small",
    };

    const refreshToken = async (type) => {
        await Auth.refreshToken(localStorage.getItem("freshToken"))
            .then((res) => {
                localStorage.setItem("token", res.data.access);
                setErrors([]);
                if (type === "delete") deleteAsset(currentItem.id);
            })
            .catch(() => changeStatus());
    };
    const deleteAsset = async (id) => {
        setIsPreloader(true);
        await SandboxService.deleteLicense(id)
            .then(() => {
                setIsPreloader(false);
                setDeleteItem(false);
            })
            .catch((e) => {
               
                    setIsPreloader(false);
                    setErrors(e.message);
                }
        );
    };
    const switchActivationAsset = async (item) => {
        const formData = new FormData();

        setIsPreloader(true);
        formData.append("is_active", !item.is_active);
        await SandboxService.switchActivateSandbox(item.id, formData)
            .then(() => {
                setIsPreloader(false);
                setSwitchActive(false);
            })
            .catch((e) => {
                if (e.message[0].includes("token")) refreshToken("put");
                else {
                    setIsPreloader(false);
                    setErrors(e.message);
                }
            });
    };
const clearFilters = ()=> {
    setProducerFilter(false)
    setTypeFilter(false)
    setFilteredArray(null)
    setOnlineFilter(false)
}

const copyBufferText = (text, field) => {
    navigator.clipboard.writeText(text)
        .then((res) => {

            setCopyText({ text: 'Copy', field: field })
        })
        .catch(err => {
            setCopyText({ text: 'Error', field: field });
        });
}
    return (
        <>
   {viewDetail?.open && (
                <MyModalFeed
                    visible={viewDetail?.open}
                    setVisible={setViewDetail}
                >
                   <LicenseDetail license_subject={viewDetail?.item?.id}  setviewFileDetail={setViewDetail} />
                </MyModalFeed>
            )}
            {patchSandbox && (
                // <MyModal visible={patchSandbox} setVisible={setPatchSandbox}>
                    <PatchLicenseForm
                        id={currentItem?.id}
                        data = {currentItem}
                        setAddLicense={setPatchSandbox}
                    />
                // </MyModal>
            )}
            {switchActive && (
                <MyConfirm visible={switchActive} setVisible={setSwitchActive}>
                    <Row className="mb-4" style={{ position: "relative" }}>
                        {isPreloader && <Preloader leftCoord={true} />}
                        <h3 className="mb-4 mt-4">
                            Вы точно хотите{" "}
                            {currentItem.is_active === false
                                ? "активировать"
                                : "деактивировать"}{" "}
                            {<br />}устройство {currentItem.serial}?
                        </h3>
                    </Row>
                    <Row>
                        {" "}
                        {errors.length > 0 &&
                            errors.map((error) => (
                                <Alert key="danger" variant="danger">
                                    {error}
                                </Alert>
                            ))}
                    </Row>
                    <Row>
                        <Col>
                            <Button
                                variant="outline-primary"
                                size="sm"
                                type="submit "
                                style={{ width: "100%" }}
                                onClick={(e) => {
                                    e.preventDefault();
                                    switchActivationAsset(currentItem);
                                }}
                            >
                                Да
                            </Button>
                        </Col>
                        <Col>
                            <Button
                                variant="outline-danger"
                                size="sm"
                                onClick={(e) => {
                                    e.preventDefault();
                                    setSwitchActive(false);
                                    setErrors([]);
                                }}
                                style={{ width: "100%" }}
                            >
                                нет
                            </Button>
                        </Col>
                    </Row>
                </MyConfirm>
            )}
            {deleteItem && (
                <MyConfirm visible={deleteItem} setVisible={setDeleteItem}>
                    <Row className="mb-4" style={{ position: "relative" }}>
                        {isPreloader && <Preloader leftCoord={true} />}
                        <h3 className="mb-4 mt-4">
                            Вы точно хотите удалить лицензию{<br />}{" "}
                            {currentItem.name}?
                        </h3>
                    </Row>
                    <Row>
                        {" "}
                        {errors?.length > 0 &&
                            errors?.map((error) => (
                                <Alert key="danger" variant="danger">
                                    {error}
                                </Alert>
                            ))}
                    </Row>
                    <Row>
                        <Col>
                            <Button
                                variant="outline-primary"
                                size="sm"
                                type="submit "
                                style={{ width: "100%" }}
                                onClick={(e) => {
                                    e.preventDefault();
                                    deleteAsset(currentItem.id);
                                }}
                            >
                                Да
                            </Button>
                        </Col>
                        <Col>
                            <Button
                                variant="outline-danger"
                                size="sm"
                                onClick={(e) => {
                                    e.preventDefault();
                                    setDeleteItem(false);
                                    setErrors([]);
                                }}
                                style={{ width: "100%" }}
                            >
                                нет
                            </Button>
                        </Col>
                    </Row>
                </MyConfirm>
            )}
            <Row className="justify-content-between mt-3 mb-4">
                {!addLicense && (
                    <Col className="col-2 mt-2 mb-2">
                        <Button
                            variant="outline-success"
                            size="sm"
                            style={{ width: "100%" }}
                            onClick={(e) => {
                                setAddLicense(true);
                            }}
                        >
                            Добавить
                        </Button>
                    </Col>
                )}
                {/* {!addLicense && (
                    <Col className="col-2 mt-2 mb-2">
                        <Button
                            variant="primary"
                            size="sm"
                            style={{ width: "100%" }}
                            onClick={(e) => {
                                setRenew(!renew);
                            }}
                        >
                            Обновить
                        </Button>
                    </Col>
                )} */}
            </Row>
            {addLicense && <AddLicenseForm setAddLicense={setAddLicense} />}
            <Row
                style={{
                    margin: " 0 0 3px",
                    padding: "0 0 0 5px",
                    position: "relative",
                }}
                className="justify-content-between"
            >
                {isPreloader && <Preloader leftCoord={true} />}
                <Col className="col-3 mt-2 mb-2">
                    <b>Всего {dataCount} шт.</b>
                </Col>
            </Row>
            <hr style={{ margin: "5px 0" }} /> 
            <Row style={{ margin: " 0 0 3px", padding: "0 0 0 5px" }}></Row>
            {
                <div
                    style={{
                        position: "relative",
                        minHeight: "375px",
                        display: "flex",
                        flexDirection: "column",
                    }}
                >
                    <div className="table-responsive">
                        <table
                            className="table align-middle table-striped table-bordered"
                            id="checkUrlResp"
                        >
                            <thead>
                                <tr style={dateStyles}> 
                                <th style={{ width: "5%" }}>
                                        Активна
                                    </th>
                                <th>Имя </th>
                                    <th>Организация</th>
                                    <th>Дата начала {<br/>}(UTC)</th>
                                    <th>Дата окончания {<br/>}(UTC)</th>
                                    <th>Сервис</th>
                                    <th>Ресурсы{<br/>}и доступы</th>
                                    <th>Лимит{<br/>} файлов{<br/>}в сутки</th>
                                    <th>Устройства</th>
                                  
                                    
                                    {/* <th>Описание</th> */}
                                    <th>Токен</th>
                                    <th>Детали</th>
                                    <th style={{ width: "5%" }}>Изменить</th> 
                                    <th style={{ width: "5%" }}>Удалить</th>
                                </tr>
                            </thead>

                            <tbody
                                className="table-group-divider"
                                id={"chDevExt"}
                            >
                                {licensesData?.map((item, index) => (
                                    <tr
                                        key={item.id + "licenses"}
                                        style={dateStyles}
                                    >
                                        <td>
                                        <td>{item.is_active ? <span style={{ color: "green" }}>Да</span> : <span style={{ color: "red" }}>Нет</span>}</td>
                                        </td>
                                        <td >
                                      {item.name}</td>
                                        <td>{item.organisation_name}</td>
                                        <td>{item.issued_at}</td>
                                        <td>{item.time_left_verbose? dateEndStatus(item.license_expires_at, item.time_left_verbose): item.license_expires_at}</td>
                                        <td>{item.license_audience}</td>
                                        <td >{item.license_scope?.map((it) => <div title={it?.description}key={it.id + 'scope'} style={{width: '250px',textOverflow: "ellipsis", overflow:"hidden", whiteSpace:'nowrap'}}>{it?.description}</div>)}</td>
                                        <td>{item.daily_requests_limit}</td>
                                        <td>{item?.devices_count || 0}</td>
                                        {/* <td>{item.description}</td> */}
                                        <td title={item.license_token}>{item.license_token ? <><img width="20" className="interactive_hover" alt={'copy token'} src={CopyIcon} onClick={
                                (evt) => {
                                    evt.stopPropagation();
                                    copyBufferText(item.license_token, 'access');
                                    setCurrentItem(item)

                                }
                            } />
                                {item.license_token === currentItem?.license_token && copytext ? <span style={copytext.text === 'Ошибка' ? { color: "red" } : { color: "green" }}>{copytext.field === "access" ? copytext.text : ''}</span> : <span></span>}
                            </> : ''}
                            </td>
                                        {/* <td>
                                            {
                                                <Col className="col-2">
                                                    <div
                                                        className="form-check form-switch"
                                                        style={{ minHeight: 0 }}
                                                    >
                                                        <input
                                                            className="form-check-input"
                                                            type="checkbox"
                                                            id="statusChecks"
                                                            checked={
                                                                item.is_active ===
                                                                !true
                                                                    ? false
                                                                    : true
                                                            }
                                                            onChange={(e) => {
                                                                setSwitchActive(
                                                                    true
                                                                );
                                                                setCurrentItem(
                                                                    item
                                                                );
                                                            }}
                                                        />
                                                        <label
                                                            className="form-check-label"
                                                            htmlFor="flexSwitchCheckChecked"
                                                        ></label>
                                                    </div>
                                                </Col>
                                            }{" "}
                                        </td> */}
                                         <td>
                                            <Button
                                                variant="primary"
                                                style={{
                                                    fontSize: "10px",
                                                    padding: 3,
                                                }}
                                                onClick={() => {
                                                    setViewDetail({
                                                        open: true,
                                                        item: item,
                                                    });
                                                }}
                                            >
                                                Details
                                            </Button>
                                        </td>
                                        <td>
                                            <img
                                                width="20"
                                                className="interactive_hover"
                                                alt={"edit"}
                                                src={ImgEdit}
                                                disabled
                                                onClick={() => {
                                                    setCurrentItem(item);
                                                    setPatchSandbox(true);
                                                }}
                                            />
                                        </td>
                                       
                                        <td
                                            style={{
                                                cursor: "pointer",
                                                padding: "4px",
                                            }}
                                        >
                                            <img
                                                width="20"
                                                src={ImgRemove}
                                                alt="delete"
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    setDeleteItem(true);
                                                    setCurrentItem(item);
                                                }}
                                            />
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                    <div ref={lastItem} style={{ height: 20 }} />
                </div>
            }
        </>
    );
}

export default LicenseList;
