import React, { useContext, useEffect, useState } from "react";
import Button from "react-bootstrap/esm/Button";
import Col from "react-bootstrap/esm/Col";
import Row from "react-bootstrap/esm/Row";
import Table from 'react-bootstrap/Table';
import ApiGatewayService from "../../API/ApiGatewayService";
import ImgEdit from '../../icons/edit.png';
import MyModal from "../UI/MyModal/MyModal";
import AddtokenForm from "./AddTokenForm";
import PatchTokenForm from "./PatchTokenForm";
import Preloader from "../UI/Preloader/Preloader";
import CopyIcon from '../../icons/copy.svg';
import Dropdown from 'react-bootstrap/Dropdown';
import MyToggleAsset from "../UI/MyDropdown/MyToggleAsset";
import MyMenu from "../UI/MyDropdown/MyMenu";
import Form from 'react-bootstrap/Form';
import ImgRemove from '../../icons/remove.png';
import TokenCard from "./TokenCard";
import MyConfirm from "../UI/MyConfirm/MyConfirm";
import { Alert } from "react-bootstrap";
import Auth from "../../API/Auth";
import { AuthContext } from "../../context";
import { changeDate } from "./utils";

const TokenList = () => {
    const [patchToken, setPatchToken] = useState(false);
    const [data, setData] = useState(null)
    // eslint-disable-next-line no-unused-vars
    const [errors, setErrors] = useState([]);
    const [addToken, setAddToken] = useState(false);
    const [successAdd, setSuccessAdd] = useState(false);
    const [successPatch, setSuccessPatch] = useState(false);
    const [successDelete, setSuccessDelete] = useState(false);
    const [currentToken, setCurrentToken] = useState(null);
    const [isPreloader, setIsPreloader] = useState(false);
    const [copytext, setCopyText] = useState(false);
    const [searchText, setSearchText] = useState('')
    const [scopeFilter, setScopeFilter] = useState(false);
    const [scopeList, setScopeList] = useState(null);
    const [activeFilter, setActiveFilter] = useState(null);
    const [typeFilter, setTypeFilter] = useState(null);
    const [page, setPage] = useState(0);
    const [orderDayLimit, setOrderDayLimit] = useState(false);
    const [orderMonthLimit, setOrederMonthLimit] = useState(false);
    const [orderTotalLimit, setOrederTotalLimit] = useState(false);
    const [orderDateEnd, setOrderDateEnd] = useState(false)
    const [viewDetail, setViewDetail] = useState(false);
    const [deleteItem, setDeleteItem] = useState(false);
const [errorsAuth, setErrorsAuth] = useState([])
    const basicStyles = {
        "fontSize": '10px',
        cursor: 'pointer'
    }

    //контекст
    const { setStatus } = useContext(AuthContext);
    const changeStatus = () => setStatus(true);
    useEffect(() => {
        (async () => {
            let filters = false;
            setOrderDayLimit(false);
            setOrederMonthLimit(false);
            setOrederTotalLimit(false);
            setOrderDateEnd(false)
            if (searchText || scopeFilter || activeFilter || typeFilter ) {
                filters = {
                    search: searchText,
                    scope__id: scopeFilter?.id,
                    is_active: activeFilter?.value,
                    type: typeFilter?.toLowerCase().replace(/ /g, '-'),
                   // order_by: orderDateEnd? orderDateEnd==='↑'? 'date_end':'-date_end': false 

                };
            }
            setIsPreloader(true)

            await ApiGatewayService.getTokenList(filters).then((res) => { setData(res); }).catch((err) => { setErrorsAuth(err.message) }).finally(() => { setIsPreloader(false); })
        }
        )()
    }, [successAdd, successPatch, searchText, activeFilter, scopeFilter, typeFilter, successDelete,])
    useEffect(() => {

        (async () => {
            await ApiGatewayService.getScopeList().then((res) => {setErrors([]); setScopeList(res)}).catch((err)=> setErrorsAuth(err.message))

        })();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);



    const copyBufferText = (text, field) => {
        navigator.clipboard.writeText(text)
            .then((res) => {

                setCopyText({ text: 'Copy', field: field })
            })
            .catch(err => {
                setCopyText({ text: 'Error', field: field });
            });
    }
    const clearFilters = () => {
        setScopeFilter(null);
        setActiveFilter(null)
        setSearchText('');
        setTypeFilter(null);
        setOrderDateEnd(false)
    }

    const newPaginationString = (count) => {
        let paginationArr = []
        let arrPages = [];
        let allPages = Math.floor(count / 20);
        if (count % 20 === 0) allPages = Math.floor(count / 20) - 1;
        for (let i = 0; i <= allPages; i++) { arrPages.push(i) };
        if (count) {
            if (allPages <= 10)
                return arrPages.map((item, index) => <li key={'cont' + item} className="page-item"><button className={item === page ? 'page-link active' : ' page-link'} onClick={() => handleClick(index)} >{index + 1}</button></li>)
            else if (page < 7 && allPages > 10) {
                paginationArr = arrPages.slice(0, 8); paginationArr.push('...', arrPages.length - 1); return paginationArr.map((item, index) => <li key={'cont' + item} className="page-item"><button className={item === page ? 'page-link active' : ' page-link'} onClick={() => item !== '...' && handleClick(item)} >{item !== "..." ? item + 1 : item}</button></li>)
            }
            else if (page > arrPages.length - 7 && allPages > 10) {
                paginationArr = arrPages.slice(arrPages.length - 8, arrPages.length); paginationArr.unshift(0, '...'); return paginationArr.map((item, index) => <li key={item === '...' ? item + index : 'cont' + item} className="page-item"><button className={item === page ? 'page-link active' : ' page-link'} onClick={() => item !== '...' && handleClick(item)} >{item !== "..." ? item + 1 : item}</button></li>)
            }
            else { paginationArr = arrPages.length > 0 && arrPages.slice(page - 3, page + 4); paginationArr.unshift(0, '...'); paginationArr.push('...', arrPages.length - 1); return paginationArr.map((item, index) => <li key={item === '...' ? item + index : 'cont' + item} className="page-item"><button className={item === page ? 'page-link active' : ' page-link'} onClick={() => item !== '...' && handleClick(item)} >{item !== "..." ? item + 1 : item}</button></li>) }
        }
    }
    const handleClick = (index) => {

        if (index === 0) setPage(0)
        else setPage(index)

    }
    const viewLimits = (item, typeReq) => {
        if (item.is_active) {
            if (item.type === "trial" || item.type === 'standard' ) {
                return typeReq === 'days' ? item.last_day_requests + '/' + item.daily_requests_limit : item.last_month_requests + '/' + item.monthly_requests_limit
            } 
            else if (item.type ==='sandbox-license') {
                return typeReq === 'days' ? item.last_day_requests + '/' + item.daily_requests_limit : <span>{item.last_month_requests} / &infin;</span>
            }
            else {
                return typeReq === 'days' ? <span >{item.last_day_requests}/&infin;</span> : <span >{item.last_month_requests}/&infin;</span>
            }
        } else return <span style={{ color: "red" }}>N/A</span>
    }
    const handleIncreasePage = () => {
        if (page < Math.floor(data.length) && (page + 1) * 20 < data.length) {
            setPage(page + 1)
        }
    }

    const handleOrderFilter = ({ orderDay, orderMonth, orderTotal, orderDateEnd }) => {
        setPage(0);
        let sortFilter = []
        if (orderDay === '↑') {sortFilter = data?.sort((a, b) => a.last_day_requests - b.last_day_requests);}
        else if (orderDay === '↓') { sortFilter = data?.sort((a, b) => b.last_day_requests - a.last_day_requests); }
        else if (orderDay === false) { sortFilter = data?.slice() }
        let sortFilterMonth = []
        if (orderMonth === '↑') {sortFilterMonth = sortFilter?.sort((a, b) => a.last_month_requests - b.last_month_requests);}
        else if (orderMonth === '↓') { sortFilterMonth = sortFilter?.sort((a, b) => b.last_month_requests - a.last_month_requests); }
        else if (orderMonth === false) { sortFilterMonth = sortFilter?.slice() }
        let sortFilterTotal = []
        if (orderTotal === '↑') {sortFilterTotal = sortFilterMonth?.sort((a, b) => a.total_requests - b.total_requests);}
        else if (orderTotal === '↓') { sortFilterTotal = sortFilterMonth?.sort((a, b) => b.total_requests - a.total_requests); }
        else if (orderTotal === false) { sortFilterTotal = sortFilterMonth?.slice() }
        let sortFilterTotalDate = []
        if (orderDateEnd === '↑') {sortFilterTotalDate = sortFilterTotal?.sort((a, b) => changeDateFilter(a.date_end) - changeDateFilter(b.date_end)); }
        else if (orderDateEnd=== '↓') { sortFilterTotalDate = sortFilterTotal?.sort((a, b) => changeDateFilter(b.date_end) - changeDateFilter(a.date_end));}
        else if (orderDateEnd === false) { sortFilterTotalDate = sortFilterTotal?.slice() }
        setData(sortFilterTotalDate);
    }
    const changeDateFilter = (date) => {
        const formatDate = new Date(date);
        return formatDate.getTime()
    }
    const handleOrderDayLimit = (value) => {
        setOrederMonthLimit(false);
        setOrederTotalLimit(false)
        setOrderDayLimit(value);
        setOrderDateEnd(false)
        handleOrderFilter({ orderDay: value, orderMonth: false, orderTotal: false, orderDateEnd: false  })
    }
    const handleOrderMonthLimit = (value) => {
        setOrderDayLimit(false);
        setOrederTotalLimit(false)
        setOrederMonthLimit(value)
        setOrderDateEnd(false)
        handleOrderFilter({ orderDay: false, orderMonth: value, orderTotal: false, orderDateEnd: false  })
    }
    const handleOrderTotalLimit = (value) => {
        setOrderDayLimit(false);
        setOrederMonthLimit(false);
        setOrederTotalLimit(value);
        setOrderDateEnd(false)
        handleOrderFilter({ orderDay: false, orderMonth: false, orderTotal: value, orderDateEnd: false })
    }
    const handleOrderDateEnd = (value) => {
        setOrderDayLimit(false);
        setOrederMonthLimit(false);
        setOrederTotalLimit(false);
        setOrderDateEnd(value)
        handleOrderFilter({ orderDay: false, orderMonth: false, orderTotal: false, orderDateEnd: value})
    }
    const refreshToken = async (subject) => {
        await Auth.refreshToken(localStorage.getItem('freshToken'))
            .then((res) => {
                localStorage.setItem('token', res.data.access); setErrors([]);
               deleteToken(subject);

            })
            .catch(() => changeStatus())
    }
    const deleteToken= async (id) => {
        setIsPreloader(true);
        await ApiGatewayService.deleteToken(id)
            .then(() => { setIsPreloader(false); setDeleteItem(false); setSuccessDelete(!successDelete) })
            .catch((e) => {
                if (e.message[0].includes('token')) refreshToken(currentToken.subject);
                else { setIsPreloader(false); setErrors(e.message); }
            });
    }

    const dateEndStatus = (date, status) => {
        if (status.includes('дн') && (Number(status.split(' ')[0]) === 0)) return <span style={{  fontSize: 'x-small' }} className="badge bg-danger">{date} ({status})</span>;
        else if (status.includes('дн') && (Number(status.split(' ')[0]) <= 31)) return <span style={{  fontSize: 'x-small' }} className="badge bg-warning">{date} ({status})</span>;
        else return <span style={{  fontSize: 'x-small' }} className="badge bg-success">{date} ({status})</span>
    }
    return (
        <>
                    {deleteItem && <MyConfirm visible={deleteItem} setVisible={setDeleteItem} >
                <Row className="mb-4" style={{ position: "relative" }}>
                    {isPreloader && <Preloader leftCoord={true} />}
                    <h3 className="mb-4 mt-4">Вы точно хотите удалить токен{<br />} {currentToken.name}?</h3>
                </Row>
                <Row> {errors.length > 0 &&
                    errors.map(error =>
                        <Alert key="danger" variant="danger">
                            {error}
                        </Alert>
                    )
                }</Row>
                <Row>
                    <Col >
                        <Button variant="outline-primary" size="sm" type="submit " style={{ width: '100%' }} onClick={(e) => { e.preventDefault(); deleteToken(currentToken.subject) }}>
                            Да
                        </Button>

                    </Col>
                    <Col >
                        <Button variant="outline-danger" size="sm" onClick={(e) => { e.preventDefault(); setDeleteItem(false); setErrors([]) }} style={{ width: '100%' }}>
                            нет
                        </Button>
                    </Col>
                </Row>
            </MyConfirm>}
            {viewDetail && <MyModal visible={viewDetail} setVisible={setViewDetail}>
                <div style={{ width: '50vw', margin: '0 auto', maxHeight: '80vh', overflowY: 'scroll' }}>
                    <Row style={{ margin: 0 }}>

                        <h5 className="mb-3">Детальная информация</h5>
                    </Row>

                    <Row style={{ margin: 0 }}>
                        {<TokenCard data={currentToken}

                        />}
                    </Row>

                </div>
            </MyModal>}
            <Row style={{ position: "relative" }}>
            {errorsAuth.length > 0 &&
                    errorsAuth.map(error =>
                        <Alert key="danger" variant="danger">
                            {error}
                        </Alert>
                    )
                }
                {isPreloader && <Preloader />}
                {(!addToken && !patchToken) && <Col className="mb-3 col-2">
                    <Button variant="outline-success" size="sm" style={{ fontSize: '1rem', width: '100%' }}
                        onClick={() => { setAddToken(true) }}
                    >Добавить Токен</Button>

                </Col>}
            </Row>
            {addToken && <AddtokenForm setAddToken={setAddToken} setSuccessAdd={setSuccessAdd} successAdd={successAdd} />}
            {/* {patchToken && currentToken && <MyModal visible={patchToken} setVisible={setPatchToken}>
                <div style={{ width: '60vw', margin: '0 auto', maxHeight: '80vh' }}>
                    <PatchTokenForm currentToken={currentToken} setPatchToken={setPatchToken} setSuccessPatch={setSuccessPatch} successPatch={successPatch} /></div>
            </MyModal>} */}
            {patchToken && currentToken &&

                <PatchTokenForm currentToken={currentToken} setPatchToken={setPatchToken} setSuccessPatch={setSuccessPatch} successPatch={successPatch} />
            }
            <hr />
            <Row className="mb-2">
                <Col className="mb-3 col-2" >
                    <Form.Label size="sm">Поиск</Form.Label>
                    <Form.Control onChange={(e) => { setSearchText(e.target.value) }}
                        placeholder="Начните вводить..."
                        size="sm"
                        style={{ fontSize: '1rem', height: 38 }}
                        value={searchText}

                    />
                </Col>

                <Col className="mb-3 col-2" >
                    <Form.Label size="sm">Ресурсы/доступы</Form.Label>
                    <Dropdown >
                        <Dropdown.Toggle as={MyToggleAsset} id="dropdown-platform" >
                            {scopeFilter?.name || "выбрать"}
                        </Dropdown.Toggle>
                        <Dropdown.Menu
                            as={MyMenu}
                            filterAssets={setScopeFilter}
                            type='scopefilter'

                        >
                            {scopeList?.map(scope =>
                                <Dropdown.Item eventKey={"apl" + scope.id} key={"apl" + scope.id} onClick={() => { setScopeFilter(scope) }}>{scope.name}</Dropdown.Item>
                            )}
                        </Dropdown.Menu>
                    </Dropdown>
                </Col>
                <Col className="mb-3 col-2" >
                    <Form.Label size="sm">Активен</Form.Label>
                    <Dropdown >
                        <Dropdown.Toggle as={MyToggleAsset} id="dropdown-active" >
                            {activeFilter?.label || "выбрать"}
                        </Dropdown.Toggle>
                        <Dropdown.Menu
                            as={MyMenu}
                            // filter={setActiveFilter}
                            type='activeFilter'
                        >
                            <Dropdown.Item eventKey={"af" + 1} key={"af" + 1} onClick={() => { setActiveFilter({ value: 'true', label: 'Активен' }) }}>{'Активен'}</Dropdown.Item>
                            <Dropdown.Item eventKey={"af" + 2} key={"af" + 2} onClick={() => { setActiveFilter({ value: 'false', label: 'Неактивен' }) }}>{'Неактивен'}</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </Col>
                <Col className="mb-3 col-2" >
                    <Form.Label size="sm">Тип</Form.Label>
                    <Dropdown >
                        <Dropdown.Toggle as={MyToggleAsset} id="dropdown-active" >
                            {typeFilter || "выбрать"}
                        </Dropdown.Toggle>
                        <Dropdown.Menu
                            as={MyMenu}
                            // filter={setActiveFilter}
                            type='typeFilter'
                        >
                            <Dropdown.Item eventKey={"tf" + 1} key={"tf" + 1} onClick={() => { setTypeFilter('TRIAL') }}>{'TRIAL'}</Dropdown.Item>
                            <Dropdown.Item eventKey={"tf" + 2} key={"tf" + 2} onClick={() => { setTypeFilter('STANDARD') }}>{'STANDARD'}</Dropdown.Item>
                            <Dropdown.Item eventKey={"tf" + 3} key={"tf" + 3} onClick={() => { setTypeFilter('UNLIMITED') }}>{'UNLIMITED'}</Dropdown.Item>
                            <Dropdown.Item eventKey={"tf" + 4} key={"tf" + 4} onClick={() => { setTypeFilter('UNLIMITED WITHOUT UPDATING') }}>{'UNLIMITED WITHOUT UPDATING'}</Dropdown.Item>
                            <Dropdown.Item eventKey={"tf" + 5} key={"tf" + 5} onClick={() => { setTypeFilter('SANDBOX LICENSE') }}>{'SANDBOX LICENSE'}</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </Col>
                {(scopeFilter || searchText || activeFilter || typeFilter) &&
                    <Col className="col-2">

                        <Button variant="outline-info" size="sm" style={{ width: "100%", height: '38px', margin: '32px 0 10px' }} onClick={() => clearFilters()}>Сбросить фильтры</Button>
                    </Col>}
            </Row>
            <Table striped bordered hover id='tableTokens' >
                <thead style={basicStyles}>
                    <tr>
                        <th>Активен</th>
                        <th>Имя</th>
                        <th>Тип</th>
                        <th>Организация</th>
                        <th>Сервис</th>
                        <th>Ресурсы <br />и доступы</th>
                        <th>Дата начала</th>
                        <th>Дата окончания {<span style={{ color: orderDateEnd === '↑' ? "blue" : "black", marginLeft: '5px', fontWeight: 900, fontSize: '16px', cursor: "pointer" }} onClick={() => orderDateEnd !== '↑' ? handleOrderDateEnd('↑') : handleOrderDateEnd(false)}>↑</span>}{<span style={{ color: orderDateEnd === '↓' ? "blue" : "black", marginLeft: '5px', fontWeight: 900, fontSize: '16px', cursor: "pointer" }} onClick={() => orderDateEnd !== '↓' ? handleOrderDateEnd('↓') : handleOrderDateEnd(false)}>↓</span>}</th>
                        <th>Запросы за<br /> сутки{<span style={{ color: orderDayLimit === '↑' ? "blue" : "black", marginLeft: '5px', fontWeight: 900, fontSize: '16px', cursor: "pointer" }} onClick={() => orderDayLimit !== '↑' ? handleOrderDayLimit('↑') : handleOrderDayLimit(false)}>↑</span>}{<span style={{ color: orderDayLimit === '↓' ? "blue" : "black", marginLeft: '5px', fontWeight: 900, fontSize: '16px', cursor: "pointer" }} onClick={() => orderDayLimit !== '↓' ? handleOrderDayLimit('↓') : handleOrderDayLimit(false)}>↓</span>}</th>
                        <th>Запросы за< br /> 30 дней{<span style={{ color: orderMonthLimit === '↑' ? "blue" : "black", marginLeft: '5px', fontWeight: 900, fontSize: '16px', cursor: "pointer" }} onClick={() => orderMonthLimit !== '↑' ? handleOrderMonthLimit('↑') : handleOrderMonthLimit(false)}>↑</span>}{<span style={{ color: orderMonthLimit === '↓' ? "blue" : "black", marginLeft: '5px', fontWeight: 900, fontSize: '16px', cursor: "pointer" }} onClick={() => orderMonthLimit !== '↓' ? handleOrderMonthLimit('↓') : handleOrderMonthLimit(false)}>↓</span>}</th>
                        <th>Запросов <br /> всего {<span style={{ color: orderTotalLimit === '↑' ? "blue" : "black", marginLeft: '5px', fontWeight: 900, fontSize: '16px', cursor: "pointer" }} onClick={() => orderTotalLimit !== '↑' ? handleOrderTotalLimit('↑') : handleOrderTotalLimit(false)}>↑</span>}{<span style={{ color: orderTotalLimit === '↓' ? "blue" : "black", marginLeft: '5px', fontWeight: 900, fontSize: '16px', cursor: "pointer" }} onClick={() => orderTotalLimit !== '↓' ? handleOrderTotalLimit('↓') : handleOrderTotalLimit(false)}>↓</span>}</th>
                        <th>Refresh Token</th>
                        <th>Access Token</th>
                        <th style={{ width: '5%' }}>Изменить</th>
                        <th style={{ width: '5%' }}>Удалить</th>

                    </tr>
                </thead>
                <tbody>

                    {data?.map((item, index) =>
                        <tr key={item.subject + 'token'}
                            style={basicStyles}
                            onClick={(evt) => { setCurrentToken(item); setViewDetail(true) }}
                        >
                            <td>{item.is_active ? <span style={{ color: "green" }}>Да</span> : <span style={{ color: "red" }}>Нет</span>}</td>
                            <td>{item.name}</td>
                            <td>{item.type}</td>
                            <td>{item.organisation}</td>
                            <td>{item.audience?.name}</td>
                            <td>{item.scope?.map((item) => <span key={item.id} style={{ whiteSpace: 'nowrap', overflow: 'hidden' }}>{item.name}<br /></span>)}</td>
                            <td>{changeDate(item.issued_at)}</td>
                            <td><div style={{display: "flex", alignItems: "center"}}>{dateEndStatus(changeDate(item.date_end), item.time_left_verbose)}</div></td>
                            <td>{viewLimits(item, 'days')}</td>
                            <td>{viewLimits(item, 'month')}</td>
                            <td>{item.total_requests}</td>

                            <td title={item.type !=='unlimited-without-updating' && item.type!=='sandbox-license'? item.refresh_token:''}>{item.type!=='unlimited-without-updating' && item.type!=='sandbox-license'&&<><img width="20" className="interactive_hover" alt={'copy token'} src={CopyIcon} onClick={
                                (evt) => {
                                    evt.stopPropagation();
                                    copyBufferText(item.refresh_token, 'refresh');
                                    setCurrentToken(item)

                                }
                            } />
                                {item.subject === currentToken?.subject && copytext ? <span style={copytext === 'Ошибка' ? { color: "red" } : { color: "green" }}>{copytext.field === "refresh" ? copytext.text : ''}</span> : <span></span>}
                            </>}</td>
                            <td title={item.access_token}>{item.access_token ? <><img width="20" className="interactive_hover" alt={'copy token'} src={CopyIcon} onClick={
                                (evt) => {
                                    evt.stopPropagation();
                                    copyBufferText(item.access_token, 'access');
                                    setCurrentToken(item)

                                }
                            } />
                                {item.subject === currentToken?.subject && copytext ? <span style={copytext.text === 'Ошибка' ? { color: "red" } : { color: "green" }}>{copytext.field === "access" ? copytext.text : ''}</span> : <span></span>}
                            </> : ''}
                            </td>

                            <td>{item.type!=='sandbox-license' && <img width="20" className="interactive_hover" alt={'edit'} src={ImgEdit} onClick={
                                (evt) => {
                                    setCopyText(null)
                                    evt.stopPropagation();
                                    setCurrentToken(item)
                                    setPatchToken(true)
                                }
                            } />}
                            </td>
                            <td>
                            {item.type!=='sandbox-license' &&<button 
                            style={{border: 'none', background: 'transparent'}}
                            onClick={
                                (evt) => {
                                    setCopyText(null)
                                    evt.stopPropagation();
                                    setCurrentToken(item)
                                    setDeleteItem(true)
                                }
                            } ><img width="20" className="interactive_hover" alt={'delete'} src={ImgRemove}  /></button>}
                            </td>
                        </tr>
                    )
                    }
                </tbody>
            </Table>
            <nav aria-label="pagination" style={{ marginTop: 'auto' }} >
                <ul className="pagination justify-content-center" id='pagination-menu' style={{ margin: "0, auto" }}>
                    {data && <li className="page-item">
                        <button disabled={page === 0 ? true : false} className="page-link" aria-label="Previous" onClick={() => page !== 0 ? setPage(page - 1) : setPage(0)}>
                            <span aria-hidden="true">&laquo;</span>

                        </button> </li>}
                    {newPaginationString(data?.length)}
                    {data && <li className="page-item">
                        <button className="page-link" aria-label="Next" onClick={() => handleIncreasePage()}>
                            <span aria-hidden="true">&raquo;</span>

                        </button> </li>}
                </ul>
            </nav>
        </>
    )
}

export default TokenList;
